.intro {
	&__head {
		position: relative;
		margin-bottom: rem(15px);

		@media (min-width: 576px) {
			margin-bottom: rem(30px); }

		&--image {
			padding-right: rem(90px);
			box-sizing: border-box;

			@media (min-width: 576px) {
				padding-right: em(130px); }

			img {
				display: block;
				position: absolute;
				width: auto;
				height: auto;
				right: 0;
				bottom: rem(5px);

				@media (min-width: 576px) {
					bottom: rem(7px); } } } }

	&--large &__head {
		@media (min-width: 992px) {
			max-width: rem(840px);
			margin-right: rem(280px); } }

	&__body {
		display: grid;
		grid-gap: rem(15px);
		grid-template-columns: minmax(0, #{(rem(360px))}) rem(170px);
		justify-content: space-between;

		@media(min-width: 576px) {
			grid-gap: rem(30px);
			grid-template-columns: minmax(0, #{(rem(360px))}) rem(250px); }

		@media(min-width: 992px) {
			grid-template-columns: minmax(0, #{(rem(360px))}) rem(360px) rem(250px);
 } }			//grid-template-columns: minmax(0, #{(rem(360px))}) minmax(0, #{(rem(360px))}) rem(250px)

	&--large &__body {
		@media(min-width: 992px) {
			grid-template-columns: minmax(0, #{(rem(840px))}) minmax(#{(rem(200px))}, #{(rem(250px))}); } }

	&__image {
		order: 1;

		img {
			display: block;
			width: 100%;
			height: auto; } }

	&--circle &__image {
		img {
			border-radius: 50%;
			overflow: hidden; } }

	&--large &__image {}

	&__copy,
	&__navi {
		@include child-fix();
		order: 3;
		grid-column: 1 / 3;

		@media(min-width: 992px) {
			order: 2;
			grid-column: 2 / 3; } }

	&__contact {
		padding-left: rem(10px);
		box-sizing: border-box;
		align-self: stretch;
		display: flex;
		flex-direction: column;
		border-left: rem(2px) solid $color-grey-light;
		order: 2;

		@media (min-width: 576px) {
			padding-left: rem(30px); }

		@media (min-width: 992px) {
			order: 3; }

		&-body {
			flex: 1 0 auto; }

		&-foot {
			flex: 0 0 auto;
			margin-top: rem(30px);

			@media (min-width: 576px) {
				margin-top: rem(45px); } }

		&-item:not(:first-child) {
			margin-top: rem(30px);

			@media (min-width: 576px) {
				margin-top: rem(45px); } }

		&-title {

			margin: 0; }

		&-meta {
			color: $color-blue-dark; }

		&-copy {
			margin: rem(15px) 0 0;

			@media (min-width: 576px) {
				margin: rem(19px) 0 0; } }

		&-widget:not(:first-child) {
			margin-top: rem(30px);

			@media (min-width: 576px) {
				margin-top: rem(45px); } } }


	.night-theme & {

		&__contact {
			&::before {
				border-left: 2px solid $color-white; }

			&-title {
				color: $color-white; }

			&-meta {
				color: $color-white; } } } }
