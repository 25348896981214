.headline {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	color: $color-blue-dark;

	&--h2 {
		justify-content: center; }

	&__icon {
		margin-right: rem(5px);

		@media (min-width: 576px) {
			margin-right: rem(15px); } }

	&--h1 &__icon {
		font-size: rem(30px);
		margin-bottom: rem(-15px);
		margin-top: rem(-15px);

		@media (min-width: 576px) {
			font-size: rem(60px);
			margin-bottom: rem(-30px);
			margin-top: rem(-30px); } }

	&--h2 &__icon {
		font-size: rem(32px);
		margin-bottom: rem(-16px);
		margin-top: rem(-16px);

		@media (min-width: 576px) {
			font-size: rem(40px);
			margin-bottom: rem(-20px);
			margin-top: rem(-20px); } }

	&__text {
		color: inherit;
		margin: 0; }

	&--h2 &__text {
		text-transform: uppercase; }

	.night-theme & {
		color: $color-white; } }
