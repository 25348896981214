.flyout {
	position: fixed;
	display: none;
	top: 0;
	right: 0;
	bottom: 0;
	width: rem(250px);
	overflow: auto;
	background-color: $color-blue-light;
	color: $color-white;
	filter: drop-shadow(rem(-2px) 0 rem(6px) #0000005C);
	z-index: 120;
	border-top-left-radius: rem(5px);
	border-bottom-left-radius: rem(5px);
	transform: translate(110%, 0);
	transition: transform 0.6s;

	@media (min-width: 576px) {
		width: rem(360px);
		border-top-left-radius: rem(10px);
		border-bottom-left-radius: rem(10px); }

	&--initialized {
		display: block;

		@media (min-width: 1200px) {
			display: none; } }

	&--visible {
		transform: translate(0,0); }

	&__wrapper {
		display: grid;
		min-height: 100%;
		grid-template-columns: 1fr;
		grid-gap: rem(30px);
		justify-content: space-between;
		padding: rem(30px);
		box-sizing: border-box;

		@media (min-width: 576px) {
			padding: rem(60px); } }

	&__navigation {
		&-level {}

		&--minimized &-level {
			&--level-2 {
				display: none; } }

		&-list {
			list-style: none;
			margin: 0;
			padding: 0 0 0 rem(15px);

			@media (min-width: 576px) {
				padding-left: rem(20px); }

			&--level-1 {
				font-size: rem(14px);
				line-height: (15px / 14px);
				padding-left: 0;

				@media (min-width: 576px) {
					font-size: rem(17px);
					line-height: (19px / 17px); } }

			&--level-2 {
				font-size: rem(12px);
				line-height: (15px / 12px);
				padding-left: 0;

				@media (min-width: 576px) {
					font-size: rem(15px);
					line-height: (19px / 15px); } } }

		&-item {
			display: block;

			&--level-1 {
				margin: rem(20px) 0;

				&:first-child {
					margin-top: 0; }

				&:last-child {
					margin-bottom: 0; } } }

		&-link {
			color: inherit;

			@at-root span#{&} {
				cursor: default; }

			@at-root a#{&} {
				text-decoration: none;
				transition: color 0.6s;

				&:hover {
					color: $color-blue-dark;
					text-decoration: none;
					transition: color 0.2s; } }

			&--active,
			&--trail {
				color: $color-blue-dark; }

			&--level-1 {
				font-weight: 700;
				text-transform: uppercase; } }

		&-toggler {
			appearance: none;
			display: none;
			width: 0;
			height: 0;
			padding: 0;
			margin: rem(30px) auto;
			text-indent: -9999em;
			overflow: hidden;
			background: transparent;
			color: inherit;
			border: {
				top: 0;
				right: rem(7px) solid transparent;
				bottom: rem(16px) solid;
				left: rem(7px) solid transparent; } }

		&--initialized &-toggler {
			display: block; }

		&--minimized &-toggler {
			border: {
				top: rem(16px) solid;
				bottom: 0; } } }

	&__quick {
		@include margin-fix();
		align-self: end;

		&-list {
			list-style: none;
			margin: 0;
			padding: 0;

			display: flex;
			flex-direction: row;
			font-size: rem(12px);
			line-height: (15px / 12px);
			margin: 0 rem(-10px);

			@media (min-width: 576px) {
				font-size: rem(15px);
				line-height: (19px / 15px); } }

		&-item {
			margin: 0 rem(10px); }

		&-link {
			color: inherit;

			@at-root span#{&} {
				cursor: default; }

			@at-root a#{&} {
				text-decoration: none;
				transition: color 0.6s;

				&:hover {
					color: $color-blue-dark;
					text-decoration: none;
					transition: color 0.2s; } }

			&--active,
			&--trail {
				color: $color-blue-dark; } } }

	&__close {
		position: absolute;
		display: block;
		top: rem(18px);
		right: rem(18px);
		width: rem(12px);
		height: rem(12px);
		color: inherit;
		border: 0;
		background: none;
		padding: 0;
		appearance: 0;
		text-indent: -9999em;
		overflow: hidden;

		@media (min-width: 576px) {
			top: rem(42px);
			right: rem(42px);
			width: rem(18px);
			height: rem(18px); }


		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: rem(2px);
			height: 140%;
			top: 50%;
			left: 50%;
			background-color: currentColor;
			transform: translate(-50%, -50%); }

		&::before {
			transform: translate(-50%, -50%) rotate(45deg); }

		&::after {
			transform: translate(-50%, -50%) rotate(135deg); } } }
