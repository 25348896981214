#wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	color: $color-grey;
	background-color: $color-white;

	.night-theme & {
		color: $color-white;
		background-color: $color-blue-dark; } }

#header {
	position: relative;
	flex: 0 0 auto;
	z-index: 40; }

#main {
	position: relative;
	flex: 1 0 auto;
	z-index: 10; }

#aside {
	position: relative;
	flex: 0 0 auto;
	z-index: 30; }

#footer {
	position: relative;
	flex: 0 0 auto;
	z-index: 20; }
