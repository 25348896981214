body {
	font-family: 'PT Sans', sans-serif;
	font-size: rem(12px);
	line-height: (15px / 12px);
	hyphens: auto;

	@media (min-width: 576px) {
		font-size: rem(15px);
		line-height: (19px / 15px); } }

h1 {
	font-size: rem(16px);
	line-height: (20px / 16px);
	color: $color-blue-dark;

	@media (min-width: 576px) {
		font-size: rem(30px);
		line-height: (38px / 30px); } }

h2 {
	font-size: rem(18px);
	line-height: (24px / 18px);
	color: $color-blue-dark;

	@media (min-width: 576px) {
		font-size: rem(26px);
		line-height: (35px / 26px); } }


h3 {
	font-size: rem(14px);
	line-height: (16px / 14px);
	color: $color-blue-dark;

	@media (min-width: 576px) {
		font-size: rem(18px);
		line-height: (21px / 18px); } }


a {
	color: $color-blue-light;
	text-decoration: none;

	&:hover {
		text-decoration: underline; } }


p,
ul,
ol {
	margin: rem(15px) 0;

	@media (min-width: 576px) {
		margin: rem(19px) 0; }

	&.blue {
		color: $color-blue-dark; } }



.night-theme {
	h1 {
		color: $color-white; }

	h2 {
		color: $color-white; }

	h3 {
		color: $color-white; }

	p,
	ul,
	ol {
		&.blue {
			color: $color-white; } } }
