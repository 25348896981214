@charset "UTF-8";
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document ========================================================================== */
/** 1. Correct the line height in all browsers. 2. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS. */
html { line-height: 1.15; /* 1 */ -ms-text-size-adjust: 100%; /* 2 */ -webkit-text-size-adjust: 100%; /* 2 */ }

/* Sections ========================================================================== */
/** Remove the margin in all browsers (opinionated). */
body { margin: 0; }

/** Add the correct display in IE 9-. */
article, aside, footer, header, nav, section { display: block; }

/** Correct the font size and margin on `h1` elements within `section` and `article` contexts in Chrome, Firefox, and Safari. */
h1 { font-size: 2em; margin: 0.67em 0; }

/* Grouping content ========================================================================== */
/** Add the correct display in IE 9-. */
figcaption, figure { display: block; }

/** Add the correct margin in IE 8. */
figure { margin: 1em 40px; }

/** 1. Add the correct box sizing in Firefox. 2. Show the overflow in Edge and IE. */
hr { box-sizing: content-box; /* 1 */ height: 0; /* 1 */ overflow: visible; /* 2 */ }

/** Add the correct display in IE. */
main { display: block; }

/** 1. Correct the inheritance and scaling of font size in all browsers. 2. Correct the odd `em` font sizing in all browsers. */
pre { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

/* Links ========================================================================== */
/** 1. Remove the gray background on active links in IE 10. 2. Remove gaps in links underline in iOS 8+ and Safari 8+. */
a { background-color: transparent; /* 1 */ -webkit-text-decoration-skip: objects; /* 2 */ }

/* Text-level semantics ========================================================================== */
/** 1. Remove the bottom border in Chrome 57- and Firefox 39-. 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari. */
abbr[title] { border-bottom: none; /* 1 */ text-decoration: underline; /* 2 */ text-decoration: underline dotted; /* 2 */ }

/** Prevent the duplicate application of `bolder` by the next rule in Safari 6. */
b, strong { font-weight: inherit; }

/** Add the correct font weight in Chrome, Edge, and Safari. */
b, strong { font-weight: bolder; }

/** 1. Correct the inheritance and scaling of font size in all browsers. 2. Correct the odd `em` font sizing in all browsers. */
code, kbd, samp { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

/** Add the correct font style in Android 4.3-. */
dfn { font-style: italic; }

/** Add the correct background and color in IE 9-. */
mark { background-color: #ff0; color: #000; }

/** Add the correct font size in all browsers. */
small { font-size: 80%; }

/** Prevent `sub` and `sup` elements from affecting the line height in all browsers. */
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }

sub { bottom: -0.25em; }

sup { top: -0.5em; }

/* Embedded content ========================================================================== */
/** Add the correct display in IE 9-. */
audio, video { display: inline-block; }

/** Add the correct display in iOS 4-7. */
audio:not([controls]) { display: none; height: 0; }

/** Remove the border on images inside links in IE 10-. */
img { border-style: none; }

/** Hide the overflow in IE. */
svg:not(:root) { overflow: hidden; }

/* Forms ========================================================================== */
/** 1. Change the font styles in all browsers (opinionated). 2. Remove the margin in Firefox and Safari. */
button, input, optgroup, select, textarea { font-family: sans-serif; /* 1 */ font-size: 100%; /* 1 */ line-height: 1.15; /* 1 */ margin: 0; /* 2 */ }

/** Show the overflow in IE. */
button { overflow: visible; }

/** Remove the inheritance of text transform in Edge, Firefox, and IE. 1. Remove the inheritance of text transform in Firefox. */
button, select { /* 1 */ text-transform: none; }

/** 1. Prevent a WebKit bug where (2) destroys native `audio` and `video` controls in Android 4. 2. Correct the inability to style clickable types in iOS and Safari. */
button, html [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; /* 2 */ }

button, [type="button"], [type="reset"], [type="submit"] { /** Remove the inner border and padding in Firefox. */ /** Restore the focus styles unset by the previous rule. */ }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }

/** Show the overflow in Edge. */
input { overflow: visible; }

/** 1. Add the correct box sizing in IE 10-. 2. Remove the padding in IE 10-. */
[type="checkbox"], [type="radio"] { box-sizing: border-box; /* 1 */ padding: 0; /* 2 */ }

/** Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

/** 1. Correct the odd appearance in Chrome and Safari. 2. Correct the outline style in Safari. */
[type="search"] { -webkit-appearance: textfield; /* 1 */ outline-offset: -2px; /* 2 */ /** Remove the inner padding and cancel buttons in Chrome and Safari on macOS. */ }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

/** 1. Correct the inability to style clickable types in iOS and Safari. 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button { -webkit-appearance: button; /* 1 */ font: inherit; /* 2 */ }

/** Correct the padding in Firefox. */
fieldset { padding: 0.35em 0.75em 0.625em; }

/** 1. Correct the text wrapping in Edge and IE. 2. Correct the color inheritance from `fieldset` elements in IE. 3. Remove the padding so developers are not caught out when they zero out `fieldset` elements in all browsers. */
legend { box-sizing: border-box; /* 1 */ display: table; /* 1 */ max-width: 100%; /* 1 */ padding: 0; /* 3 */ color: inherit; /* 2 */ white-space: normal; /* 1 */ }

/** 1. Add the correct display in IE 9-. 2. Add the correct vertical alignment in Chrome, Firefox, and Opera. */
progress { display: inline-block; /* 1 */ vertical-align: baseline; /* 2 */ }

/** Remove the default vertical scrollbar in IE. */
textarea { overflow: auto; }

/* Interactive ========================================================================== */
/* Add the correct display in Edge, IE, and Firefox. */
details { display: block; }

/* Add the correct display in all browsers. */
summary { display: list-item; }

/* Add the correct display in IE 9-. */
menu { display: block; }

/* Scripting ========================================================================== */
/** Add the correct display in IE 9-. */
canvas { display: inline-block; }

/** Add the correct display in IE. */
template { display: none; }

/* Hidden ========================================================================== */
/** Add the correct display in IE 10-. */
[hidden] { display: none; }

body { font-family: 'PT Sans', sans-serif; font-size: 0.75rem; line-height: 1.25; hyphens: auto; }

@media (min-width: 576px) { body { font-size: 0.9375rem; line-height: 1.26667; } }

h1 { font-size: 1rem; line-height: 1.25; color: #002c57; }

@media (min-width: 576px) { h1 { font-size: 1.875rem; line-height: 1.26667; } }

h2 { font-size: 1.125rem; line-height: 1.33333; color: #002c57; }

@media (min-width: 576px) { h2 { font-size: 1.625rem; line-height: 1.34615; } }

h3 { font-size: 0.875rem; line-height: 1.14286; color: #002c57; }

@media (min-width: 576px) { h3 { font-size: 1.125rem; line-height: 1.16667; } }

a { color: #8cbee6; text-decoration: none; }

a:hover { text-decoration: underline; }

p, ul, ol { margin: 0.9375rem 0; }

@media (min-width: 576px) { p, ul, ol { margin: 1.1875rem 0; } }

p.blue, ul.blue, ol.blue { color: #002c57; }

.night-theme h1 { color: white; }

.night-theme h2 { color: white; }

.night-theme h3 { color: white; }

.night-theme p.blue, .night-theme ul.blue, .night-theme ol.blue { color: white; }

label { display: inline-block; font-size: 0.75rem; line-height: 1.25; color: #002c57; margin-right: 0.3125rem; }

@media (min-width: 576px) { label { font-size: 0.875rem; line-height: 1.28571; margin-right: 0.625rem; } }

select { display: inline-block; border: 1px solid; height: 1.75rem; border-radius: 0.1875rem; padding: 0.4375rem 0.625rem; background: transparent; box-sizing: border-box; font-size: 0.625rem; line-height: 1.2; color: #002c57; background-color: white; width: 10rem; max-width: 100%; }

input[type="text"], input[type="password"] { display: inline-block; border: 1px solid; height: 1.75rem; border-radius: 0.1875rem; padding: 0.4375rem 0.625rem; background: transparent; box-sizing: border-box; font-size: 0.625rem; line-height: 1.2; color: #002c57; background-color: white; width: 12.5rem; max-width: 100%; }

input[type="text"]::placeholder, input[type="password"]::placeholder { color: #707070; }

input[type="checkbox"] { position: absolute; display: block; width: 1px; height: 1px; opacity: 0.01; }

input[type="checkbox"] + label { display: inline-block; margin-right: none; }

input[type="checkbox"] + label .checkbox-switch { vertical-align: middle; display: inline-block; border: 1px solid; border-radius: 0.1875rem; font-size: 0.625rem; line-height: 1.2; margin-right: 0.625rem; }

input[type="checkbox"] + label .checkbox-switch__off { position: relative; display: inline-block; height: 1.125rem; padding-top: 0.1875rem; padding-right: 0.375rem; padding-bottom: 0.1875rem; padding-left: 0.25rem; background-color: #002c57; color: white; box-sizing: border-box; }

input[type="checkbox"] + label .checkbox-switch__off::after { content: ''; position: absolute; display: inline-block; top: 0; right: 0; border-top: 1.125rem solid transparent; border-right: 0.1875rem solid white; }

input[type="checkbox"] + label .checkbox-switch__on { position: relative; display: inline-block; height: 1.125rem; padding-top: 0.1875rem; padding-right: 0.25rem; padding-bottom: 0.1875rem; padding-left: 0.1875rem; box-sizing: border-box; }

input[type="checkbox"]:checked + label .checkbox-switch { background-color: #002c57; border-color: #002c57; color: white; }

input[type="checkbox"]:checked + label .checkbox-switch__off { background-color: white; color: #002c57; }

input[type="checkbox"]:checked + label .checkbox-switch__off::after { border-right-color: #002c57; }

.night-theme label { color: white; }

.night-theme select { color: white; background-color: #002c57; }

.night-theme input[type="text"], .night-theme input[type="password"] { color: white; background-color: #002c57; }

.night-theme input[type="text"]::placeholder, .night-theme input[type="password"]::placeholder { color: white; }

.night-theme input[type="checkbox"] + label .checkbox-switch { border: 1px solid white; background-color: #002c57; color: white; }

.night-theme input[type="checkbox"] + label .checkbox-switch__off { background-color: white; color: #002c57; }

.night-theme input[type="checkbox"] + label .checkbox-switch__off::after { border-right-color: #002c57; }

.night-theme input[type="checkbox"]:checked + label .checkbox-switch { background-color: white; color: #002c57; }

.night-theme input[type="checkbox"]:checked + label .checkbox-switch__off { background-color: #002c57; color: white; }

.night-theme input[type="checkbox"]:checked + label .checkbox-switch__off::after { border-right-color: white; }

.svg-symbols { width: 0; height: 0; overflow: hidden; }

.svg-theme-day { display: inline-block; font-size: 1.875rem; width: 1.06667em; height: 1em; }

.svg-theme-night { display: inline-block; font-size: 1.875rem; width: 1.06667em; height: 1em; }

.svg-header-divider { display: inline-block; font-size: 11.5rem; width: 0.53261em; height: 1em; }

.svg-hero-circle { display: inline-block; font-size: 28.75rem; width: 1em; height: 1em; }

.svg-icon-calendar { display: inline-block; font-size: 4.75rem; width: 0.89474em; height: 1em; }

.svg-icon-download { display: inline-block; font-size: 4.75rem; width: 1em; height: 1em; }

.svg-icon-galaxy { display: inline-block; font-size: 4.75rem; width: 1em; height: 1em; }

.svg-icon-home { display: inline-block; font-size: 4.75rem; width: 0.97368em; height: 1em; }

.svg-icon-library { display: inline-block; font-size: 4.75rem; width: 1.13158em; height: 1em; }

.svg-icon-magneticfield { display: inline-block; font-size: 4.75rem; width: 1.15789em; height: 1em; }

.svg-icon-molecule { display: inline-block; font-size: 4.75rem; width: 1.23684em; height: 1em; }

.svg-icon-network { display: inline-block; font-size: 4.75rem; width: 1em; height: 1em; }

.svg-icon-news { display: inline-block; font-size: 4.75rem; width: 0.76316em; height: 1em; }

.svg-icon-planetarium { display: inline-block; font-size: 4.75rem; width: 1.07895em; height: 1em; }

.svg-icon-project { display: inline-block; font-size: 4.75rem; width: 1em; height: 1em; }

.svg-icon-stars { display: inline-block; font-size: 4.75rem; width: 1.07895em; height: 1em; }

.svg-icon-technology { display: inline-block; font-size: 4.75rem; width: 1.13158em; height: 1em; }

.svg-icon-twitter { display: inline-block; font-size: 4.75rem; width: 1.23684em; height: 1em; }

/* CSS Styles that are needed by jScrollPane for it to operate correctly. Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane may not operate correctly without them. */
.jspContainer { overflow: hidden; position: relative; }

.jspPane { position: absolute; }

.jspVerticalBar { background: red; height: 100%; position: absolute; right: 0; top: 0; width: 16px; }

.jspHorizontalBar { background: red; bottom: 0; height: 16px; left: 0; position: absolute; width: 100%; }

.jspCap { display: none; }

.jspHorizontalBar .jspCap { float: left; }

.jspTrack { background: #dde; position: relative; }

.jspDrag { background: #bbd; cursor: pointer; left: 0; position: relative; top: 0; }

.jspHorizontalBar .jspTrack, .jspHorizontalBar .jspDrag { float: left; height: 100%; }

.jspArrow { background: #50506d; cursor: pointer; direction: ltr; display: block; margin: 0; padding: 0; text-indent: -20000px; }

.jspDisabled { background: #80808d; cursor: default; }

.jspVerticalBar .jspArrow { height: 16px; }

.jspHorizontalBar .jspArrow { float: left; height: 100%; width: 16px; }

.jspVerticalBar .jspArrow:focus { border: 1px solid red; outline: none; }

.jspCorner { background: #eeeef4; float: left; height: 100%; }

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner { margin: 0 -3px 0 0; }

.jspVerticalBar { width: 0.875rem; background: transparent; }

.jspArrow { color: #002c57; background: transparent; height: 0; width: 0; }

.jspVerticalBar .jspArrow { height: 0; border-left: 0.4375rem solid transparent; border-right: 0.4375rem solid transparent; }

.jspVerticalBar .jspArrowUp { height: 0; border-bottom: 1rem solid; }

.jspVerticalBar .jspArrowDown { height: 0; border-top: 1rem solid; }

.jspHorizontalBar { display: none; }

.jspDisabled { color: rgba(0, 44, 87, 0.3); background: transparent; }

.jspTrack { background: transparent; }

.jspDrag { display: none; }

.night-theme .jspArrow { color: white; }

.night-theme .jspDisabled { color: rgba(255, 255, 255, 0.3); }

.scrollpane { max-height: 31.25rem; overflow-x: hidden; overflow-y: auto; }

.scrollpane__content { padding-right: 0.9375rem; }

@media (min-width: 576px) { .scrollpane__content { padding-right: 1.875rem; } }

.circle-navigation { max-width: 17.75rem; margin: 0 auto; }

.circle-navigation::before, .circle-navigation::after { content: ''; display: table; }

@media (min-width: 576px) { .circle-navigation { max-width: 22.5rem; } }

.circle-navigation--odd { padding: 0.4375rem 0; }

@media (min-width: 576px) { .circle-navigation--odd { padding: 0.9375rem 0; } }

.circle-navigation__list { list-style: none; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; margin: -0.4375rem; padding: 0; }

@media (min-width: 576px) { .circle-navigation__list { margin: -0.9375rem; } }

.circle-navigation__item { padding: 0.4375rem; width: 50%; box-sizing: border-box; }

@media (min-width: 576px) { .circle-navigation__item { padding: 0.9375rem; } }

.circle-navigation--odd .circle-navigation__item:first-child { margin: 0 25% -0.875rem; }

@media (min-width: 576px) { .circle-navigation--odd .circle-navigation__item:first-child { margin-bottom: -1.875rem; } }

.circle-navigation__link { position: relative; display: block; width: 100%; height: 0; padding-bottom: 100%; border-radius: 50%; overflow: hidden; }

.circle-navigation__image { position: absolute; display: block; top: 0; left: 0; width: 100%; height: 100%; }

.circle-navigation__label { position: absolute; display: flex; justify-content: center; align-items: center; top: 0; left: 0; width: 100%; height: 100%; font-size: 0.75rem; font-weight: 700; line-height: 1.25; text-transform: uppercase; color: white; background-color: rgba(0, 44, 87, 0.6); text-align: center; padding: 0.9375rem; box-sizing: border-box; }

@media (min-width: 576px) { .circle-navigation__label { font-size: 0.9375rem; line-height: 1.26667; } }

figure { display: block; margin: 0; padding: 0; }

figcaption { font-size: 0.625rem; line-height: 1.4; margin-top: 0.625rem; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

.slick-dots { list-style: none; margin: 0 -0.5625rem; padding: 0; display: flex; flex-direction: row; justify-content: center; align-items: center; }

.slick-dots li { margin: 0 0.5625rem; }

.slick-dots li button { appearance: none; display: block; width: 0.75rem; height: 0.75rem; border-width: 0; border-radius: 0.375rem; padding: 0; background-color: #002c57; text-indent: -9999em; overflow: hidden; transition: background-color 0.6s; }

.slick-dots li.slick-active button { background-color: #8cbee6; transition: background-color 0.2s; }

.night-theme .slick-dots li button { background-color: white; }

.night-theme .slick-dots li.slick-active button { background-color: #8cbee6; }

.calendar { display: grid; grid-template-columns: minmax(0, 30rem); justify-content: space-around; grid-gap: 1.875rem 0.9375rem; }

@media (min-width: 576px) { .calendar { grid-gap: 3.75rem 1.875rem; } }

@media (min-width: 992px) { .calendar { grid-template-columns: 22.5rem minmax(0, 42.5rem); justify-content: space-between; } }

.calendar__widget-navigation { display: flex; flex-direction: row; justify-content: space-between; align-items: center; max-width: 15.625rem; margin: 0 auto 1.875rem; color: #002c57; }

.calendar__widget-prev, .calendar__widget-next { color: inherit; display: block; width: 0; height: 0; border-top: 0.3125rem solid transparent; border-bottom: 0.3125rem solid transparent; }

.calendar__widget-prev { border-right: 0.6875rem solid; }

.calendar__widget-next { border-left: 0.6875rem solid; }

.calendar__widget-month { font-size: 1.125rem; font-weight: 700; line-height: 1.11111; }

.calendar__widget-filter { margin: 1.875rem 0; text-align: center; }

.calendar__widget-grid { display: grid; grid-gap: 1rem; grid-template-columns: repeat(7, 1.875rem); justify-content: center; overflow-x: auto; }

@media (min-width: 576px) { .calendar__widget-grid { grid-gap: 1.125rem; grid-template-columns: repeat(7, 2.125rem); } }

.calendar__widget-weekday { font-size: 0.8125rem; line-height: 1.23077; text-align: center; text-transform: uppercase; }

@media (min-width: 576px) { .calendar__widget-weekday { font-size: 0.9375rem; line-height: 1.26667; } }

.calendar__widget-day { position: relative; display: block; width: 1.875rem; height: 1.875rem; border: 1px solid; border-radius: 50%; box-sizing: border-box; font-size: 0.75rem; line-height: 2.5; text-align: center; color: inherit; text-decoration: none; }

@media (min-width: 576px) { .calendar__widget-day { width: 2.125rem; height: 2.125rem; font-size: 0.8125rem; line-height: 2.61538; } }

.calendar__widget-day:hover { text-decoration: none; }

.calendar__widget-day--current::before { content: ''; position: absolute; display: block; left: 50%; bottom: -0.4375rem; width: 0.375rem; height: 0.375rem; margin: -0.1875rem; background-color: #707070; border-radius: 50%; }

.calendar__widget-day--yellow { background-color: #fbba00; border-color: #fbba00; color: #707070; }

.calendar__widget-day--blue-light { background-color: #8cbee6; border-color: #8cbee6; color: white; }

.calendar__widget-day--blue-dark { background-color: #002c57; border-color: #002c57; color: white; }

.calendar__widget-day--blue-dark.calendar__widget-day--blue-light { box-shadow: 5px 0 0 #8cbee6; }

.calendar__widget-day--blue-dark.calendar__widget-day--yellow, .calendar__widget-day--blue-light.calendar__widget-day--yellow { box-shadow: -5px 0 0 #fbba00; }

.calendar__widget-day--blue-dark.calendar__widget-day--blue-light.calendar__widget-day--yellow { box-shadow: -5px 0 0 #fbba00, 5px 0 0 #8cbee6; }

.calendar__content { display: flex; flex-direction: column; align-items: stretch; }

.calendar__preview { order: 2; }

@media (min-width: 992px) { .calendar__preview { order: 1; } }

.calendar__preview .scrollpane { max-height: 18.75rem; }

.calendar__teaser { position: relative; padding-left: 1.25rem; margin-top: 0.9375rem; margin-bottom: 0.9375rem; }

@media (min-width: 576px) { .calendar__teaser { padding-left: 1.875rem; margin-top: 1.875rem; margin-bottom: 1.875rem; } }

.calendar__teaser:first-child { margin-top: 0; }

.calendar__teaser:last-child { margin-bottom: 0; }

.calendar__teaser::before { content: ''; position: absolute; display: block; top: 0.125rem; left: 0; width: 0.75rem; height: 0.75rem; border-radius: 50%; }

@media (min-width: 576px) { .calendar__teaser::before { top: 0.0625rem; width: 1.125rem; height: 1.125rem; } }

.calendar__teaser--blue-dark::before { background-color: #002c57; }

.calendar__teaser--blue-light::before { background-color: #8cbee6; }

.calendar__teaser--yellow::before { background-color: #fbba00; }

.calendar__teaser-title { margin: 0; }

.calendar__teaser-date { font-size: 0.625rem; line-height: 1.5; }

@media (min-width: 576px) { .calendar__teaser-date { font-size: 0.875rem; line-height: 1.5; } }

.calendar__teaser-meta { font-size: 0.625rem; font-style: italic; line-height: 1.5; color: #002c57; }

@media (min-width: 576px) { .calendar__teaser-meta { font-size: 0.875rem; line-height: 1.5; } }

.calendar__teaser-copy > *:first-child { margin-top: 0; }

.calendar__teaser-copy > *:last-child { margin-bottom: 0; }

.calendar__legend { order: 1; align-self: flex-end; margin-bottom: 1.875rem; }

.calendar__legend > *:first-child { margin-top: 0; }

.calendar__legend > *:last-child { margin-bottom: 0; }

@media (min-width: 576px) { .calendar__legend { margin-bottom: 3.75rem; } }

@media (min-width: 992px) { .calendar__legend { order: 2; align-self: flex-start; margin-bottom: 0; margin-top: 3.75rem; } }

.calendar__legend-entry { position: relative; margin: 0.625rem 0; padding-left: 1.125rem; font-size: 0.625rem; line-height: 1.3; color: #002c57; }

.calendar__legend-entry::before { content: ''; position: absolute; display: block; top: 0; left: 0; width: 0.8125rem; height: 0.8125rem; border-radius: 50%; }

@media (min-width: 576px) { .calendar__legend-entry { font-size: 0.6875rem; line-height: 1.36364; padding-left: 1.5625rem; }
  .calendar__legend-entry::before { width: 0.9375rem; height: 0.9375rem; } }

.calendar__legend-entry--blue-dark::before { background-color: #002c57; }

.calendar__legend-entry--blue-light::before { background-color: #8cbee6; }

.calendar__legend-entry--yellow::before { background-color: #fbba00; }

.night-theme .calendar__widget-navigation { color: white; }

.night-theme .calendar__widget-day--current::before { background-color: white; }

.night-theme .calendar__widget-day--yellow { color: #002c57; }

.night-theme .calendar__widget-day--blue-light { color: #002c57; }

.night-theme .calendar__widget-day--blue-dark { background-color: white; border-color: white; color: #002c57; }

.night-theme .calendar__teaser--blue-dark::before { background-color: white; }

.night-theme .calendar__teaser-meta { color: white; }

.night-theme .calendar__legend-entry { color: white; }

.night-theme .calendar__legend-entry--blue-dark::before { background-color: white; }

@media (min-width: 992px) { .employees { margin-right: 15.625rem; } }

.employees__image { margin-bottom: 1.875rem; }

.employees__image img { display: block; width: 100%; height: auto; }

.employees__head { margin-bottom: 0.9375rem; }

.employees__head > *:first-child { margin-top: 0; }

.employees__head > *:last-child { margin-bottom: 0; }

@media (min-width: 576px) { .employees__head { margin-bottom: 1.875rem; } }

.employees__list { display: grid; grid-gap: 0.9375rem; grid-template-columns: 1fr; justify-content: stretch; }

@media (min-width: 576px) { .employees__list { grid-gap: 1.875rem; grid-template-columns: 1fr 1fr; } }

.employees__item { max-width: 20rem; }

@media (min-width: 576px) { .employees__item { max-width: 25rem; } }

.employees__item-name { font-weight: 700; color: #002c57; }

.employees__item-position { color: #002c57; font-style: italic; }

.night-theme .employees__item-name { color: white; }

.night-theme .employees__item-position { color: white; font-style: italic; }

.filter { display: grid; grid-gap: 0.9375rem; grid-template-columns: 100%; justify-content: space-between; }

@media (min-width: 576px) { .filter { grid-gap: 0.9375rem 1.875rem; } }

@media (min-width: 992px) { .filter { grid-template-columns: repeat(3, minmax(0, 300px)); } }

.filter__widget { text-align: right; }

@media (min-width: 992px) { .filter__widget { text-align: center; } }

.filter__widget--search { order: 1; }

@media (min-width: 992px) { .filter__widget--search { order: unset; } }

.filter__widget:not(.filter__widget--search) { order: 2; }

@media (min-width: 992px) { .filter__widget:not(.filter__widget--search) { order: unset; } }

.flyout { position: fixed; display: none; top: 0; right: 0; bottom: 0; width: 15.625rem; overflow: auto; background-color: #8cbee6; color: white; filter: drop-shadow(-0.125rem 0 0.375rem #0000005C); z-index: 120; border-top-left-radius: 0.3125rem; border-bottom-left-radius: 0.3125rem; transform: translate(110%, 0); transition: transform 0.6s; }

@media (min-width: 576px) { .flyout { width: 22.5rem; border-top-left-radius: 0.625rem; border-bottom-left-radius: 0.625rem; } }

.flyout--initialized { display: block; }

@media (min-width: 1200px) { .flyout--initialized { display: none; } }

.flyout--visible { transform: translate(0, 0); }

.flyout__wrapper { display: grid; min-height: 100%; grid-template-columns: 1fr; grid-gap: 1.875rem; justify-content: space-between; padding: 1.875rem; box-sizing: border-box; }

@media (min-width: 576px) { .flyout__wrapper { padding: 3.75rem; } }

.flyout__navigation--minimized .flyout__navigation-level--level-2 { display: none; }

.flyout__navigation-list { list-style: none; margin: 0; padding: 0 0 0 0.9375rem; }

@media (min-width: 576px) { .flyout__navigation-list { padding-left: 1.25rem; } }

.flyout__navigation-list--level-1 { font-size: 0.875rem; line-height: 1.07143; padding-left: 0; }

@media (min-width: 576px) { .flyout__navigation-list--level-1 { font-size: 1.0625rem; line-height: 1.11765; } }

.flyout__navigation-list--level-2 { font-size: 0.75rem; line-height: 1.25; padding-left: 0; }

@media (min-width: 576px) { .flyout__navigation-list--level-2 { font-size: 0.9375rem; line-height: 1.26667; } }

.flyout__navigation-item { display: block; }

.flyout__navigation-item--level-1 { margin: 1.25rem 0; }

.flyout__navigation-item--level-1:first-child { margin-top: 0; }

.flyout__navigation-item--level-1:last-child { margin-bottom: 0; }

.flyout__navigation-link { color: inherit; }

span.flyout__navigation-link { cursor: default; }

a.flyout__navigation-link { text-decoration: none; transition: color 0.6s; }

a.flyout__navigation-link:hover { color: #002c57; text-decoration: none; transition: color 0.2s; }

.flyout__navigation-link--active, .flyout__navigation-link--trail { color: #002c57; }

.flyout__navigation-link--level-1 { font-weight: 700; text-transform: uppercase; }

.flyout__navigation-toggler { appearance: none; display: none; width: 0; height: 0; padding: 0; margin: 1.875rem auto; text-indent: -9999em; overflow: hidden; background: transparent; color: inherit; border-top: 0; border-right: 0.4375rem solid transparent; border-bottom: 1rem solid; border-left: 0.4375rem solid transparent; }

.flyout__navigation--initialized .flyout__navigation-toggler { display: block; }

.flyout__navigation--minimized .flyout__navigation-toggler { border-top: 1rem solid; border-bottom: 0; }

.flyout__quick { align-self: end; }

.flyout__quick::before, .flyout__quick::after { content: ''; display: table; }

.flyout__quick-list { list-style: none; margin: 0; padding: 0; display: flex; flex-direction: row; font-size: 0.75rem; line-height: 1.25; margin: 0 -0.625rem; }

@media (min-width: 576px) { .flyout__quick-list { font-size: 0.9375rem; line-height: 1.26667; } }

.flyout__quick-item { margin: 0 0.625rem; }

.flyout__quick-link { color: inherit; }

span.flyout__quick-link { cursor: default; }

a.flyout__quick-link { text-decoration: none; transition: color 0.6s; }

a.flyout__quick-link:hover { color: #002c57; text-decoration: none; transition: color 0.2s; }

.flyout__quick-link--active, .flyout__quick-link--trail { color: #002c57; }

.flyout__close { position: absolute; display: block; top: 1.125rem; right: 1.125rem; width: 0.75rem; height: 0.75rem; color: inherit; border: 0; background: none; padding: 0; appearance: 0; text-indent: -9999em; overflow: hidden; }

@media (min-width: 576px) { .flyout__close { top: 2.625rem; right: 2.625rem; width: 1.125rem; height: 1.125rem; } }

.flyout__close::before, .flyout__close::after { content: ''; position: absolute; display: block; width: 0.125rem; height: 140%; top: 50%; left: 50%; background-color: currentColor; transform: translate(-50%, -50%); }

.flyout__close::before { transform: translate(-50%, -50%) rotate(45deg); }

.flyout__close::after { transform: translate(-50%, -50%) rotate(135deg); }

.footer { position: relative; color: #707070; background-color: #d0d4d7; filter: drop-shadow(0 -0.125rem 0.375rem #0000005C); z-index: 10; }

.footer__wrapper { max-width: 84.375rem; margin-left: auto; margin-right: auto; padding-left: 1.875rem; padding-right: 1.875rem; box-sizing: border-box; }

@media (min-width: 768px) { .footer__wrapper { padding-left: 4.6875rem; padding-right: 4.6875rem; } }

.footer__background { position: relative; color: white; background-color: #002c57; filter: drop-shadow(0 -0.125rem 0.375rem #0000005C); z-index: 2; }

@supports (clip-path: url("#svg-mask-header")) { .footer__background { background-color: transparent; }
  .footer__background::before { content: ''; position: absolute; display: block; top: -2.125rem; right: 0; bottom: 0; left: 0; background-color: #002c57; clip-path: url("#svg-mask-footer"); }
  @media (min-width: 576px) { .footer__background::before { top: -2.75rem; } }
  @media (min-width: 1200px) { .footer__background::before { top: -5.625rem; } } }

.footer__top { display: flex; flex-direction: row; justify-content: flex-end; padding-top: 0.625rem; padding-bottom: 0.625rem; }

@media (min-width: 576px) { .footer__top { padding-top: 0.9375rem; padding-bottom: 0.9375rem; } }

@media (min-width: 1200px) { .footer__top { justify-content: space-between; padding-top: 2.5rem; padding-bottom: 2.5rem; } }

.footer__bottom { display: flex; flex-direction: row; justify-content: flex-start; align-items: center; padding-top: 0.625rem; padding-bottom: 0.625rem; }

@media (min-width: 576px) { .footer__bottom { padding-top: 0.9375rem; padding-bottom: 0.9375rem; } }

@media (min-width: 1200px) { .footer__bottom { padding-top: 2.5rem; padding-bottom: 2.5rem; } }

.footer__contact { max-width: 8.125rem; }

@media (min-width: 1200px) { .footer__contact { max-width: 9.375rem; } }

@media (min-width: 1200px) { .footer__contact { max-width: none; } }

.footer__contact-head { font-size: 0.6875rem; font-weight: 700; line-height: 1.18182; color: #002c57; }

@media (min-width: 576px) { .footer__contact-head { font-size: 0.8125rem; line-height: 1.15385; } }

.footer__contact-body { font-size: 0.6875rem; line-height: 1.18182; }

@media (min-width: 576px) { .footer__contact-body { font-size: 0.6875rem; line-height: 1.36364; margin: 0.9375rem 0; } }

.footer__contact-foot { font-size: 0.6875rem; line-height: 1.18182; }

@media (min-width: 576px) { .footer__contact-foot { font-size: 0.8125rem; line-height: 1.15385; } }

.footer__sitemap { display: none; max-width: 47.75rem; margin: 0 0 0 auto; }

@media (min-width: 1200px) { .footer__sitemap { display: block; } }

.footer__sitemap-list { list-style: none; margin: 0; padding: 0 0 0 0.75rem; font-size: 0.6875rem; line-height: 1.36364; }

.footer__sitemap-list--level-1 { display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); grid-gap: 1.875rem; justify-content: space-between; padding-left: 0; }

.footer__sitemap-list--level-2 { padding-left: 0; }

.footer__sitemap-link { color: inherit; }

.footer__sitemap-link--level-1 { text-transform: uppercase; font-weight: 700; }

.footer__logo { position: relative; padding: 0 0.625rem; }

@media (min-width: 576px) { .footer__logo { padding: 0 1.5625rem; } }

.footer__logo:first-child { padding-left: 0; }

.footer__logo:last-child { padding-right: 0; }

.footer__logo a { display: block; width: 100%; height: 0; text-indent: -9999em; overflow: hidden; background-size: 100% 100%; background-repeat: no-repeat; background-position: center center; }

.footer__logo--self { width: 6.375rem; }

@media (min-width: 576px) { .footer__logo--self { width: 9.5625rem; } }

@media (min-width: 1200px) { .footer__logo--self { width: 20.25rem; } }

.footer__logo--self a { padding-bottom: 26.54321%; background-image: url("../assets/header/logo_light.png"); }

.footer__logo--association { width: 2.125rem; }

@media (min-width: 576px) { .footer__logo--association { width: 3.1875rem; } }

@media (min-width: 1200px) { .footer__logo--association { width: 6.6875rem; } }

.footer__logo--association a { padding-bottom: 80.37383%; background-image: url("../assets/footer/logo_association_light.png"); }

.footer__logo--equality { width: 1.5625rem; }

@media (min-width: 576px) { .footer__logo--equality { width: 2.3125rem; } }

@media (min-width: 1200px) { .footer__logo--equality { width: 4.875rem; } }

.footer__logo--equality a { padding-bottom: 117.94872%; background-image: url("../assets/footer/logo_equality_light.png"); }

.night-theme .footer__background { background-color: white; color: #707070; }

@supports (clip-path: url("#svg-mask-footer")) { .night-theme .footer__background { background-color: transparent; }
  .night-theme .footer__background::before { background-color: white; } }

.night-theme .footer__logo--self a { background-image: url("../assets/header/logo_dark.png"); }

.night-theme .footer__logo--association a { background-image: url("../assets/footer/logo_association_dark.png"); }

.night-theme .footer__logo--equality a { background-image: url("../assets/footer/logo_equality_dark.png"); }

.header { position: relative; color: #707070; background-color: #d0d4d7; filter: drop-shadow(0 0.125rem 0.375rem #0000005C); z-index: 100; }

.header--initialized { position: fixed; top: 0; left: 0; right: 0; transition: top 1s; }

@media (min-width: 1200px) { .header--minimized { top: -5rem; } }

.header__wrapper { max-width: 84.375rem; margin-left: auto; margin-right: auto; padding-left: 1.875rem; padding-right: 1.875rem; box-sizing: border-box; }

@media (min-width: 768px) { .header__wrapper { padding-left: 4.6875rem; padding-right: 4.6875rem; } }

.header__background { position: relative; background-color: #002c57; color: white; filter: drop-shadow(0 0.125rem 0.375rem #0000005C); z-index: 2; }

@media (min-width: 1200px) { @supports (clip-path: url("#svg-mask-header")) { .header__background { background-color: transparent; }
    .header__background::before { content: ''; position: absolute; display: block; top: 0; right: 0; bottom: -7.5rem; left: 0; background-color: #002c57; clip-path: url("#svg-mask-header"); } } }

.header__top { display: grid; grid-template-columns: auto auto; justify-content: space-between; height: 3.25rem; }

@media (min-width: 576px) { .header__top { height: 4.875rem; } }

@media (min-width: 1200px) { .header__top { height: 10.5rem; } }

.header__divider { position: absolute; display: block; font-size: 3.25rem; margin-left: 6.75rem; top: 0; color: #8cbee6; pointer-events: none; }

@media (min-width: 576px) { .header__divider { font-size: 4.875rem; margin-left: 10.125rem; } }

@media (min-width: 1200px) { .header__divider { font-size: 11.5rem; margin-left: 18.75rem; } }

.header__logo { position: relative; width: 6.375rem; align-self: center; }

@media (min-width: 576px) { .header__logo { width: 9.5625rem; } }

@media (min-width: 1200px) { .header__logo { grid-row: 1 / 3; width: 20.25rem; } }

.header__logo a { display: block; width: 100%; height: 0; padding-bottom: 26.54321%; text-indent: -9999em; overflow: hidden; background-image: url("../assets/header/logo_light.png"); background-size: 100% 100%; background-repeat: no-repeat; background-position: center center; }

.header--initialized .header__logo { transition: all 1s; }

@media (min-width: 1200px) { .header--minimized .header__logo { width: 13rem; padding-top: 5rem; } }

.header__meta { position: relative; display: flex; flex-direction: row; justify-content: space-between; align-items: center; }

@media (min-width: 1200px) { .header__meta { padding-top: 2.5rem; padding-bottom: 2.5rem; } }

.header__theme { margin-right: 0.625rem; }

@media (min-width: 1200px) { .header__theme { margin-right: 0.9375rem; margin-left: -2.9375rem; } }

.header__theme-switch { display: block; border: none; background: none; padding: 0; appearance: none; color: inherit; transition: color 0.6s; cursor: pointer; }

.header__theme-switch:hover { color: #8cbee6; transition: color 0.2s; }

.header__theme-content { display: block; }

.header__theme-content--day { display: none; }

.header__theme-icon { display: block; font-size: 1.25rem; }

@media (min-width: 576px) { .header__theme-icon { font-size: 1.875rem; } }

.header__theme-label { display: none; }

.header__language { flex: 1 0 auto; }

.header__language::before, .header__language::after { content: ''; display: table; }

.header__language-list { display: flex; list-style: none; margin: 0 -0.25rem; padding: 0; font-size: 0.625rem; line-height: 1.3; }

@media (min-width: 576px) { .header__language-list { font-size: 0.9375rem; line-height: 1.26667; } }

.header__language-item { text-transform: uppercase; padding: 0 0.25rem; }

.header__language-item:not(:first-child) { position: relative; padding-left: 0.4375rem; }

.header__language-item:not(:first-child)::before { content: '|'; position: absolute; display: block; width: 0.1875rem; top: 0; left: 0; text-align: center; }

.header__language-link { display: block; }

span.header__language-link { cursor: default; }

a.header__language-link { color: inherit; text-decoration: none; transition: color 0.6s; }

a.header__language-link:hover { color: #8cbee6; text-decoration: none; transition: color 0.2s; }

.header__quick { display: none; }

.header__quick::before, .header__quick::after { content: ''; display: table; }

@media (min-width: 1200px) { .header__quick { display: flex; } }

.header__quick-list { display: flex; list-style: none; margin: 0 -1.875rem; padding: 0; }

.header__quick-item { font-size: 0.9375rem; line-height: 1.26667; text-transform: uppercase; margin: 0 1.875rem; }

.header__quick-item--active, .header__quick-item--trail { color: #8cbee6; }

.header__quick-link { display: block; }

span.header__quick-link { cursor: default; }

a.header__quick-link { color: inherit; text-decoration: none; transition: color 0.6s; }

a.header__quick-link:hover { color: #8cbee6; text-decoration: none; transition: color 0.2s; }

.header__burger { position: relative; display: block; appearance: none; padding: 0; border: none; color: inherit; width: 1.375rem; height: 1.25rem; background: none; text-indent: -9999em; overflow: hidden; margin-left: 1.25rem; }

@media (min-width: 576px) { .header__burger { width: 2.0625rem; height: 1.875rem; } }

@media (min-width: 1200px) { .header__burger { display: none; } }

.header__burger::before, .header__burger::after { content: ''; position: absolute; display: block; left: 0; width: 1.375rem; box-sizing: border-box; }

@media (min-width: 576px) { .header__burger::before, .header__burger::after { width: 2.0625rem; } }

.header__burger::before { top: 0.125rem; height: 1rem; border-top: 0.125rem solid; border-bottom: 0.125rem solid; }

@media (min-width: 576px) { .header__burger::before { top: 0.1875rem; height: 1.5rem; } }

.header__burger::after { top: 0.5625rem; height: 0; border-top: 0.125rem solid; }

@media (min-width: 576px) { .header__burger::after { top: 0.875rem; } }

.header__navigation { margin-bottom: -2.125rem; display: none; }

.header__navigation::before, .header__navigation::after { content: ''; display: table; }

@media (min-width: 1200px) { .header__navigation { display: flex; } }

.header__navigation-level--level-2 { position: absolute; display: none; left: 0; right: 0; background: #8cbee6; color: white; }

.header__navigation-list { list-style: none; margin: 0; padding: 0; }

.header__navigation-list--level-1 { display: flex; flex-direction: row; margin: 0 -1.25rem; }

.header__navigation-list--level-2 { max-width: 84.375rem; margin-left: auto; margin-right: auto; padding-left: 1.875rem; padding-right: 1.875rem; box-sizing: border-box; display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 0.625rem 1.875rem; padding-top: 1.25rem; padding-bottom: 1.25rem; }

@media (min-width: 768px) { .header__navigation-list--level-2 { padding-left: 4.6875rem; padding-right: 4.6875rem; } }

.header__navigation-list--level-3 { color: white; }

.header__navigation-item { display: block; }

.header__navigation-item--level-1 { margin: 0 1.25rem; }

.header__navigation-link { text-transform: uppercase; }

span.header__navigation-link { cursor: default; }

a.header__navigation-link { color: inherit; text-decoration: none; transition: color 0.6s; }

a.header__navigation-link:hover { text-decoration: none; transition: color 0.2s; }

.header__navigation-link--level-1 { position: relative; display: block; font-size: 1.0625rem; line-height: 1.58824; font-weight: 700; }

a.header__navigation-link--level-1:hover { color: #8cbee6; }

.header__navigation-link--level-1::after { content: ''; position: relative; display: block; width: 0; height: 0; margin: 0 auto; border-top: none; border-right: 1.0625rem solid transparent; border-bottom: 2.125rem solid transparent; border-left: 1.0625rem solid transparent; }

.header__navigation-link--level-1.header__navigation-link--active, .header__navigation-link--level-1.header__navigation-link--trail { color: #8cbee6; }

.header__navigation-link--level-2 { font-size: 0.9375rem; line-height: 1.66667; font-weight: 700; }

a.header__navigation-link--level-2:hover { color: #002c57; }

.header__navigation-link--level-2.header__navigation-link--active, .header__navigation-link--level-2.header__navigation-link--trail { color: #002c57; }

.header__navigation-link--level-3 { font-size: 0.875rem; line-height: 1.42857; font-weight: 400; }

a.header__navigation-link--level-3:hover { color: #002c57; }

.header__navigation-link--level-3.header__navigation-link--active, .header__navigation-link--level-3.header__navigation-link--trail { color: #002c57; }

.header__navigation-item--level-1:hover .header__navigation-link--level-1::after { border-bottom-color: #8cbee6; }

.header__navigation-item--level-1:hover .header__navigation-level--level-2 { display: block; }

.header__breadcrumb { color: #002c57; }

@media (min-width: 576px) { .header__breadcrumb { padding-top: 0.625rem; padding-bottom: 0.625rem; } }

@media (min-width: 1200px) { .header__breadcrumb { padding-top: 1.25rem; padding-bottom: 1.25rem; } }

.header__breadcrumb-list { list-style: none; margin: 0; padding: 0; font-size: 0.5625rem; line-height: 2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

@media (min-width: 576px) { .header__breadcrumb-list { font-size: 0.8125rem; line-height: 1.38462; } }

.header__breadcrumb-item { display: inline-block; }

.header__breadcrumb-item:not(:first-child)::before { content: '»'; }

.header__breadcrumb-link { color: inherit; text-decoration: none; }

.header__breadcrumb-link:hover { color: inherit; text-decoration: underline; }

.night-theme .header__background { background-color: white; color: #707070; }

@media (min-width: 1200px) { @supports (clip-path: url("#svg-mask-header")) { .night-theme .header__background { background-color: transparent; }
    .night-theme .header__background::before { background-color: white; } } }

.night-theme .header__logo a { background-image: url("../assets/header/logo_dark.png"); }

.night-theme .header__theme-content--day { display: block; }

.night-theme .header__theme-content--night { display: none; }

.night-theme .header__navigation { color: #002c57; }

.headline { display: flex; flex-direction: row; justify-content: flex-start; align-items: center; color: #002c57; }

.headline--h2 { justify-content: center; }

.headline__icon { margin-right: 0.3125rem; }

@media (min-width: 576px) { .headline__icon { margin-right: 0.9375rem; } }

.headline--h1 .headline__icon { font-size: 1.875rem; margin-bottom: -0.9375rem; margin-top: -0.9375rem; }

@media (min-width: 576px) { .headline--h1 .headline__icon { font-size: 3.75rem; margin-bottom: -1.875rem; margin-top: -1.875rem; } }

.headline--h2 .headline__icon { font-size: 2rem; margin-bottom: -1rem; margin-top: -1rem; }

@media (min-width: 576px) { .headline--h2 .headline__icon { font-size: 2.5rem; margin-bottom: -1.25rem; margin-top: -1.25rem; } }

.headline__text { color: inherit; margin: 0; }

.headline--h2 .headline__text { text-transform: uppercase; }

.night-theme .headline { color: white; }

.hero-slider { position: relative; }

.hero-slider__intro { position: relative; display: flex; flex-direction: column; justify-content: flex-end; align-items: center; margin: -1.875rem -1.875rem 0; padding: 4.6875rem 2.5rem; height: calc(100vh - 4.375rem); color: white; background-color: #002c57; background-image: url(../assets/hero-slider/background.jpg); background-size: cover; background-position: center bottom; box-sizing: border-box; text-align: center; }

@media (min-width: 576px) { .hero-slider__intro { display: block; background: none; margin: 0; height: auto; text-align: left; padding: 0.625rem 0 0; color: #8cbee6; z-index: 2; } }

@media (min-width: 992px) { .hero-slider__intro { position: absolute; top: 0; left: 0; } }

.hero-slider__intro-headline { font-size: 1rem; font-weight: 700; line-height: 1.25; text-transform: uppercase; }

.hero-slider__intro-copy { max-width: 17.8125rem; font-size: 0.875rem; line-height: 1.07143; }

.hero-slider__planets { position: relative; z-index: 1; }

.hero-slider__planets-decorator { position: relative; width: 90.47619%; height: 0; padding-bottom: 90.47619%; margin: 4.7619%; }

@media (min-width: 576px) { .hero-slider__planets-decorator { width: 38.33333%; padding-bottom: 38.33333%; margin: 9.16667% 31.25% 2.5% 30.41667%; } }

.hero-slider__planets-circle { position: absolute; display: block; opacity: 0; top: 0; left: 0; width: 100%; height: 100%; transform: rotate(-30deg); transition: opacity 0.5s, transform 1.5s; color: #8cbee6; }

.slick-slide.slick-current .hero-slider__planets-circle { transform: rotate(0deg); opacity: 1; transition: opacity 0.5s 1s, transform 1s 1s; }

.hero-slider__planets-image { position: absolute; display: block; width: 100%; height: 100%; padding: 9.56522%; box-sizing: border-box; }

.hero-slider__planets-image img { display: block; width: 100%; height: auto; border-radius: 50%; }

.hero-slider__menu { position: relative; max-width: 23.4375rem; margin-left: auto; margin-right: auto; z-index: 2; }

@media (min-width: 992px) { .hero-slider__menu { max-width: 31.25%; margin-top: -14.16667%; margin-right: 0; }
  .hero-slider__menu .slick-dots { justify-content: flex-start; } }

.hero-slider__contents { position: relative; max-width: 23.4375rem; margin-left: auto; margin-right: auto; z-index: 2; }

@media (min-width: 992px) { .hero-slider__contents { max-width: 31.25%; margin-right: 0; } }

.icon-navigation::before, .icon-navigation::after { content: ''; display: table; }

.icon-navigation__list { list-style: none; margin: 0; padding: 0; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; text-align: flex-start; margin: -1.875rem -0.9375rem; }

.icon-navigation__item { flex: 0 0 auto; width: 50%; padding: 1.875rem 0.9375rem; box-sizing: border-box; color: #002c57; }

@media (min-width: 576px) { .icon-navigation__item { width: 33.3333%; } }

@media (min-width: 992px) { .icon-navigation__item { width: 20%; } }

.icon-navigation__link { display: block; color: inherit; text-decoration: none; transition: color 0.6s; }

.icon-navigation__link:hover { color: #8cbee6; text-decoration: none; transition: color 0.2s; }

.icon-navigation__link-icon { display: block; margin: 0 auto; margin-bottom: 1.875rem; }

.icon-navigation__link-title { display: block; text-align: center; font-size: 0.875rem; line-height: 1; font-weight: 700; text-transform: uppercase; }

.night-theme .icon-navigation__item { color: white; }

.image img { display: block; max-width: 100%; height: auto; }

.intro__head { position: relative; margin-bottom: 0.9375rem; }

@media (min-width: 576px) { .intro__head { margin-bottom: 1.875rem; } }

.intro__head--image { padding-right: 5.625rem; box-sizing: border-box; }

@media (min-width: 576px) { .intro__head--image { padding-right: 8.125em; } }

.intro__head--image img { display: block; position: absolute; width: auto; height: auto; right: 0; bottom: 0.3125rem; }

@media (min-width: 576px) { .intro__head--image img { bottom: 0.4375rem; } }

@media (min-width: 992px) { .intro--large .intro__head { max-width: 52.5rem; margin-right: 17.5rem; } }

.intro__body { display: grid; grid-gap: 0.9375rem; grid-template-columns: minmax(0, 22.5rem) 10.625rem; justify-content: space-between; }

@media (min-width: 576px) { .intro__body { grid-gap: 1.875rem; grid-template-columns: minmax(0, 22.5rem) 15.625rem; } }

@media (min-width: 992px) { .intro__body { grid-template-columns: minmax(0, 22.5rem) 22.5rem 15.625rem; } }

@media (min-width: 992px) { .intro--large .intro__body { grid-template-columns: minmax(0, 52.5rem) minmax(12.5rem, 15.625rem); } }

.intro__image { order: 1; }

.intro__image img { display: block; width: 100%; height: auto; }

.intro--circle .intro__image img { border-radius: 50%; overflow: hidden; }

.intro__copy, .intro__navi { order: 3; grid-column: 1 / 3; }

.intro__copy > *:first-child, .intro__navi > *:first-child { margin-top: 0; }

.intro__copy > *:last-child, .intro__navi > *:last-child { margin-bottom: 0; }

@media (min-width: 992px) { .intro__copy, .intro__navi { order: 2; grid-column: 2 / 3; } }

.intro__contact { padding-left: 0.625rem; box-sizing: border-box; align-self: stretch; display: flex; flex-direction: column; border-left: 0.125rem solid #d0d4d7; order: 2; }

@media (min-width: 576px) { .intro__contact { padding-left: 1.875rem; } }

@media (min-width: 992px) { .intro__contact { order: 3; } }

.intro__contact-body { flex: 1 0 auto; }

.intro__contact-foot { flex: 0 0 auto; margin-top: 1.875rem; }

@media (min-width: 576px) { .intro__contact-foot { margin-top: 2.8125rem; } }

.intro__contact-item:not(:first-child) { margin-top: 1.875rem; }

@media (min-width: 576px) { .intro__contact-item:not(:first-child) { margin-top: 2.8125rem; } }

.intro__contact-title { margin: 0; }

.intro__contact-meta { color: #002c57; }

.intro__contact-copy { margin: 0.9375rem 0 0; }

@media (min-width: 576px) { .intro__contact-copy { margin: 1.1875rem 0 0; } }

.intro__contact-widget:not(:first-child) { margin-top: 1.875rem; }

@media (min-width: 576px) { .intro__contact-widget:not(:first-child) { margin-top: 2.8125rem; } }

.night-theme .intro__contact::before { border-left: 2px solid white; }

.night-theme .intro__contact-title { color: white; }

.night-theme .intro__contact-meta { color: white; }

#wrapper { position: relative; display: flex; flex-direction: column; width: 100%; min-height: 100vh; overflow: hidden; color: #707070; background-color: white; }

.night-theme #wrapper { color: white; background-color: #002c57; }

#header { position: relative; flex: 0 0 auto; z-index: 40; }

#main { position: relative; flex: 1 0 auto; z-index: 10; }

#aside { position: relative; flex: 0 0 auto; z-index: 30; }

#footer { position: relative; flex: 0 0 auto; z-index: 20; }

.module { position: relative; margin-top: 1.875rem; margin-bottom: 1.875rem; }

@media (min-width: 576px) { .module { margin-top: 3.75rem; margin-bottom: 3.75rem; } }

.module--space-double { margin-top: 3.125rem; margin-bottom: 3.125rem; }

@media (min-width: 576px) { .module--space-double { margin-top: 6.25rem; margin-bottom: 6.25rem; } }

.module--space-top-double { margin-top: 3.125rem; }

@media (min-width: 576px) { .module--space-top-double { margin-top: 6.25rem; } }

.module--space-bottom-double { margin-bottom: 3.125rem; }

@media (min-width: 576px) { .module--space-bottom-double { margin-bottom: 6.25rem; } }

.module__anchor { position: absolute; top: -13rem; width: 1px; height: 1px; overflow: hidden; }

.module__wrapper { max-width: 84.375rem; margin-left: auto; margin-right: auto; padding-left: 1.875rem; padding-right: 1.875rem; box-sizing: border-box; }

@media (min-width: 768px) { .module__wrapper { padding-left: 4.6875rem; padding-right: 4.6875rem; } }

.module__wrapper::before, .module__wrapper::after { content: ''; display: table; }

.news { display: grid; grid-template-columns: minmax(0, 30rem); justify-content: space-around; grid-gap: 3.125rem 0.9375rem; }

@media (min-width: 576px) { .news { grid-gap: 6.25rem 1.875rem; } }

@media (min-width: 992px) { .news { grid-template-columns: minmax(0, 37.5rem) minmax(0, 30rem); } }

.news__head { margin-bottom: 3.75rem; }

.news__body .scrollpane { max-height: 23.4375rem; overflow: hidden; }

.news__teaser { display: flex; flex-direction: column; justify-content: center; align-items: stretch; min-height: 23.4375rem; padding: 0.9375rem 0; box-sizing: border-box; }

.news__teaser-head { margin-bottom: 0.9375rem; }

.news__teaser-body { margin-bottom: 0.9375rem; }

.news__teaser-title { margin: 0; }

.quick-links { position: fixed; display: none; }

@media (min-width: 768px) { .quick-links { display: block; } }

.quick-links--left { top: 11rem; left: 0; width: 4.6875rem; }

@media (min-width: 1200px) { .quick-links--left { top: 17.875rem; } }

@media (min-width: 84.375rem) { .quick-links--left { width: calc(50% - 600px); } }

@media (min-width: 90rem) { .quick-links--left { left: calc(50% - 720px); width: 7.5rem; } }

.quick-links--right { top: 17.8125rem; bottom: 10rem; right: 0; width: 4.6875rem; }

@media (min-width: 84.375rem) { .quick-links--right { width: calc(50% - 600px); } }

@media (min-width: 90rem) { .quick-links--right { right: calc(50% - 720px); width: 7.5rem; } }

.quick-links__list { list-style: none; margin: 0; padding: 0; display: flex; height: 100%; flex-direction: column; justify-content: flex-start; align-items: center; }

.quick-links--left .quick-links__list { justify-content: flex-start; }

.quick-links--right .quick-links__list { justify-content: flex-end; }

.quick-links__item { margin-top: 2.1875rem; margin-bottom: 2.1875rem; }

.quick-links__item:first-child { margin-top: 0; }

.quick-links__item:last-child { margin-bottom: 0; }

.quick-links__link { position: relative; display: flex; justify-content: center; align-items: center; width: 2.8125rem; height: 2.8125rem; border-radius: 50%; background-color: #002c57; color: white; transition: background-color 0.6s; }

.quick-links__link:hover { background-color: #8cbee6; text-decoration: none; transition: background-color 0.2s; }

.quick-links__link[href*="#"]::after { content: ''; position: absolute; display: block; width: 0; height: 0; border-top: 0.375rem solid #002c57; border-left: 0.1875rem solid transparent; border-right: 0.1875rem solid transparent; left: 50%; top: 111.11111%; margin: -0.1875rem; transition: border-color 0.6s; }

.quick-links__link[href*="#"]:hover::after { border-top-color: #8cbee6; transition: border-color 0.2s; }

.quick-links__icon { width: 1.5625rem; height: 1.5625rem; }

.quick-links__label { display: none; }

.quick-links--left .quick-links__link { background-color: #8cbee6; }

.quick-links--left .quick-links__link:hover { background-color: #002c57; }

.quick-links--left .quick-links__link[href*="#"]::after { border-top-color: #8cbee6; }

.quick-links--left .quick-links__link[href*="#"]:hover::after { border-top-color: #002c57; }

.quick-links--right .quick-links__link { background-color: #002c57; }

.quick-links--right .quick-links__link:hover { background-color: #8cbee6; }

.quick-links--right .quick-links__link[href*="#"]::after { border-top-color: #002c57; }

.quick-links--right .quick-links__link[href*="#"]:hover::after { border-top-color: #8cbee6; }

.night-theme .quick-links__link { background-color: white; color: #002c57; }

.night-theme .quick-links__link:hover { background-color: #8cbee6; }

.night-theme .quick-links__link[href*="#"]::after { border-top-color: white; }

.night-theme .quick-links__link[href*="#"]:hover::after { border-top-color: #8cbee6; }

.night-theme .quick-links--left .night-theme .quick-links__link:hover { background-color: white; }

.night-theme .quick-links--left .night-theme .quick-links__link[href*="#"]:hover::after { border-top-color: white; }

.night-theme .quick-links--right .night-theme .quick-links__link { background-color: white; }

.night-theme .quick-links--right .night-theme .quick-links__link[href*="#"]::after { border-top-color: white; }

.teaser__list { display: grid; grid-gap: 1.875rem 0.9375rem; grid-template-columns: repeat(2, minmax(0, 300px)); justify-content: space-between; }

@media (min-width: 576px) { .teaser__list { grid-gap: 3.75rem 1.875rem; } }

@media (min-width: 992px) { .teaser__list { grid-template-columns: repeat(3, minmax(0, 300px)); } }

.teaser--latest .teaser__list--image, .teaser--latest .teaser__list--content { display: block; margin-left: -1.875rem; margin-right: -1.875rem; }

.teaser--list .teaser__list { grid-row-gap: 0.9375rem; grid-template-columns: 100%; }

@media (min-width: 576px) { .teaser--list .teaser__list { grid-row-gap: 1.875rem; } }

.teaser--circle .teaser__list { grid-template-columns: repeat(2, minmax(0, 240px)); }

@media (min-width: 992px) { .teaser--circle .teaser__list { grid-template-columns: repeat(3, minmax(0, 240px)); } }

.teaser__item:last-child:not(:nth-child(even)) { display: none; }

@media (min-width: 992px) { .teaser__item:last-child:not(:nth-child(even)) { display: initial; } }

.teaser--latest .teaser__list--image .teaser__item:last-child:not(:nth-child(even)), .teaser--latest .teaser__list--content .teaser__item:last-child:not(:nth-child(even)) { display: initial; }

.teaser--list .teaser__item { display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start; }

.teaser__image img { display: block; width: 100%; height: auto; }

.teaser--latest .teaser__list--content .teaser__image { display: none; }

.teaser--circle .teaser__image { border-radius: 50%; overflow: hidden; }

.teaser--list .teaser__image { flex: 0 0 auto; margin-right: 0.9375rem; }

@media (min-width: 576px) { .teaser--list .teaser__image { margin-right: 1.875rem; } }

.teaser--list .teaser__content { flex: 1 1 auto; }

.teaser--latest .teaser__list--image .teaser__content { display: none; }

.teaser--latest .teaser__list--content .teaser__content { padding-left: 1.875rem; padding-right: 1.875rem; }

.teaser__head { margin-top: 0.3125rem; margin-bottom: 0.3125rem; }

@media (min-width: 576px) { .teaser__head { margin-top: 0.9375rem; margin-bottom: 0.9375rem; } }

.teaser--latest .teaser__list--content .teaser__head { max-width: 13.75rem; margin: 0 auto; text-align: center; }

.teaser--list .teaser__head { margin: 0; }

.teaser__body { margin-top: 0.3125rem; margin-bottom: 0.3125rem; }

@media (min-width: 576px) { .teaser__body { margin-top: 0.9375rem; margin-bottom: 0.9375rem; } }

.teaser--list .teaser__body { margin: 0; }

.teaser__title { margin: 0; }

.teaser--latest .teaser__list--content .teaser__title { font-size: 1rem; line-height: 1.0625; }

.teaser__date { font-size: 0.625rem; line-height: 1.5; }

@media (min-width: 576px) { .teaser__date { font-size: 0.875rem; line-height: 1.5; } }

.teaser--latest .teaser__list--content .teaser__date { font-size: 0.75rem; line-height: 1.25; }

.teaser__meta { font-size: 0.625rem; font-style: italic; line-height: 1.5; color: #002c57; }

@media (min-width: 576px) { .teaser__meta { font-size: 0.875rem; line-height: 1.5; } }

.teaser--latest .teaser__list--content .teaser__meta { font-size: 0.75rem; line-height: 1.25; }

.teaser__copy > *:first-child { margin-top: 0; }

.teaser__copy > *:last-child { margin-bottom: 0; }

.teaser--latest .teaser__list--content .teaser__copy { font-size: 0.875rem; line-height: 1.07143; }

.teaser--latest .teaser__list--content .teaser__foot { max-width: 13.75rem; margin: 0 auto; text-align: center; }

.teaser__menu { margin-top: 0.9375rem; margin-bottom: 0.9375rem; }

.teaser__pagination { margin-top: 3.75rem; text-align: center; }

.teaser__pagination-more { display: inline-block; width: 0; height: 0; overflow: hidden; color: #002c57; border-top: 1.3125rem solid; border-left: 0.75rem solid transparent; border-right: 0.75rem solid transparent; }

.night-theme .teaser__meta { color: white; }

.night-theme .teaser__pagination-more { color: white; }

.text-image { display: grid; grid-gap: 0.9375rem; grid-template-columns: 100%; justify-content: space-between; }

@media (min-width: 576px) { .text-image { grid-gap: 1.875rem; } }

@media (min-width: 992px) { .text-image { grid-template-columns: minmax(0, 47.5rem) minmax(22.5rem, 26.25rem); } }

@media (min-width: 992px) { .text-image__image { order: 2; } }

.text-image__image figure { display: block; max-width: 26.25rem; margin: 0 auto; }

.text-image__image img { display: block; width: 100%; height: auto; }

.text-image__copy > *:first-child { margin-top: 0; }

.text-image__copy > *:last-child { margin-bottom: 0; }

@media (min-width: 992px) { .text-image__copy { order: 1; } }

@media (min-width: 992px) { .text-meta { margin-right: 15.625rem; } }

.text-meta__copy { margin-bottom: 0.9375rem; }

.text-meta__copy > *:first-child { margin-top: 0; }

.text-meta__copy > *:last-child { margin-bottom: 0; }

@media (min-width: 576px) { .text-meta__copy { margin-bottom: 1.875rem; } }

.text-meta__foot { display: grid; grid-gap: 0.9375rem; grid-template-columns: 1fr; }

@media (min-width: 576px) { .text-meta__foot { grid-gap: 1.875rem; grid-template-columns: 1fr 1fr; } }

.text-meta__employees, .text-meta__associates { max-width: 20rem; }

.text-meta__employees > *:first-child, .text-meta__associates > *:first-child { margin-top: 0; }

.text-meta__employees > *:last-child, .text-meta__associates > *:last-child { margin-bottom: 0; }

.text-navi { display: grid; grid-gap: 0.9375rem; grid-template-columns: 100%; justify-content: space-between; }

@media (min-width: 576px) { .text-navi { grid-gap: 1.875rem; } }

@media (min-width: 992px) { .text-navi { grid-template-columns: minmax(0, 47.5rem) minmax(22.5rem, 26.25rem); } }

@media (min-width: 992px) { .text-navi__navi { order: 2; } }

.text-navi__copy > *:first-child { margin-top: 0; }

.text-navi__copy > *:last-child { margin-bottom: 0; }

@media (min-width: 992px) { .text-navi__copy { order: 1; } }

.text__section::before, .text__section::after { content: ''; display: block; clear: both; }

.text__image img { display: block; width: auto; height: auto; max-width: 100%; }

.text__image--left { padding: 0 0.625rem 0 0; float: left; }

.text__image--right { padding: 0 0 0 0.625rem; float: right; }

* { outline: none; }
