.text-meta {

	@media (min-width: 992px) {
		margin-right: rem(250px); }

	&__copy {
		@include child-fix();
		margin-bottom: rem(15px);

		@media (min-width: 576px) {
			margin-bottom: rem(30px); } }

	&__foot {
		display: grid;
		grid-gap: rem(15px);
		grid-template-columns: 1fr;

		@media (min-width: 576px) {
			grid-gap: rem(30px);
			grid-template-columns: 1fr 1fr; } }

	&__employees,
	&__associates {
		@include child-fix();
		max-width: rem(320px); } }
