@import "slick-carousel/slick/slick.scss";

.slick {
	&-dots {
		list-style: none;
		margin: 0 rem(-9px);
		padding: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		li {
			margin: 0 rem(9px);

			button {
				appearance: none;
				display: block;
				width: rem(12px);
				height: rem(12px);
				border: {
					width: 0;
					radius: rem(6px); }
				padding: 0;
				background-color: $color-blue-dark;
				text-indent: -9999em;
				overflow: hidden;
				transition: background-color 0.6s; }

			&.slick-active {
				button {
					background-color: $color-blue-light;
					transition: background-color 0.2s; } } } }

	.night-theme & {

		&-dots {
			li {
				button {
					background-color: $color-white; }

				&.slick-active {
					button {
						background-color: $color-blue-light; } } } } } }
