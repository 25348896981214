.text-navi {
	display: grid;
	grid-gap: rem(15px);
	grid-template-columns: 100%;
	justify-content: space-between;

	@media (min-width: 576px) {
		grid-gap: rem(30px); }

	@media (min-width: 992px) {
		grid-template-columns: minmax(0, #{(rem(760px))}) minmax(#{(rem(360px))}, #{(rem(420px))}); }


	&__navi {
		@media (min-width: 992px) {
			order: 2; } }



	&__copy {
		@include child-fix();

		@media (min-width: 992px) {
			order: 1; } } }

