.icon-navigation {
	@include margin-fix();

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex: {
			direction: row;
			wrap: wrap; }
		justify-content: center;
		text-align: flex-start;
		margin: rem(-30px) rem(-15px); }


	&__item {
		flex: 0 0 auto;
		width: 50%;
		padding: rem(30px) rem(15px);
		box-sizing: border-box;
		color: $color-blue-dark;

		@media (min-width: 576px) {
			width: 33.3333%; }

		@media (min-width: 992px) {
			width: 20%; } }


	&__link {
		display: block;
		color: inherit;
		text-decoration: none;
		transition: color 0.6s;

		&:hover {
			color: $color-blue-light;
			text-decoration: none;
			transition: color 0.2s; }

		&-icon {
			display: block;
			margin: 0 auto;
			margin-bottom: rem(30px); }

		&-title {
			display: block;
			text-align: center;
			font-size: rem(14px);
			line-height: (14px / 14px);
			font-weight: 700;
			text-transform: uppercase; } }



	.night-theme & {
		&__item {
			color: $color-white; } } }
