@mixin content {
	max-width: rem(1350px);
	margin: {
		left: auto;
		right: auto; }
	padding: {
		left: rem(30px);
		right: rem(30px); }
	box-sizing: border-box;


	@media (min-width: 768px) {
		padding: {
			left: rem(75px);
			right: rem(75px); } } }

@mixin margin-fix {
	&::before,
	&::after {
		content: '';
		display: table; } }

@mixin child-fix {
	> *:first-child {
		margin-top: 0; }
	> *:last-child {
		margin-bottom: 0; } }
