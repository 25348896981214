.svg-symbols {
	width: 0;
	height: 0;
	overflow: hidden; }

@mixin svg-icon($width, $height) {
	display: inline-block;
	font-size: rem($height);
	width: em($width, $height);
	height: em($height, $height); }

.svg-theme-day {
	@include svg-icon(32px, 30px); }

.svg-theme-night {
	@include svg-icon(32px, 30px); }

.svg-header-divider {
	@include svg-icon(98px, 184px); }

.svg-hero-circle {
	@include svg-icon(460px, 460px); }

.svg-icon-calendar {
	@include svg-icon(68px, 76px); }

.svg-icon-download {
	@include svg-icon(76px, 76px); }

.svg-icon-galaxy {
	@include svg-icon(76px, 76px); }

.svg-icon-home {
	@include svg-icon(74px, 76px); }

.svg-icon-library {
	@include svg-icon(86px, 76px); }

.svg-icon-magneticfield {
	@include svg-icon(88px, 76px); }

.svg-icon-molecule {
	@include svg-icon(94px, 76px); }

.svg-icon-network {
	@include svg-icon(76px, 76px); }

.svg-icon-news {
	@include svg-icon(58px, 76px); }

.svg-icon-planetarium {
	@include svg-icon(82px, 76px); }

.svg-icon-project {
	@include svg-icon(76px, 76px); }

.svg-icon-stars {
	@include svg-icon(82px, 76px); }

.svg-icon-technology {
	@include svg-icon(86px, 76px); }

.svg-icon-twitter {
	@include svg-icon(94px, 76px); }
