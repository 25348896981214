
$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);

$color-blue-dark: rgb(0, 44, 87);
$color-blue-light: rgb(140, 190, 230);

$color-yellow: rgb(251, 186, 0);

$color-grey: rgb(112, 112, 112);
$color-grey-light: rgb(208, 212, 215);
