.text-image {
	display: grid;
	grid-gap: rem(15px);
	grid-template-columns: 100%;
	justify-content: space-between;

	@media (min-width: 576px) {
		grid-gap: rem(30px); }

	@media (min-width: 992px) {
		grid-template-columns: minmax(0, #{(rem(760px))}) minmax(#{(rem(360px))}, #{(rem(420px))}); }

	&__image {

		@media (min-width: 992px) {
			order: 2; }

		figure {
			display: block;
			max-width: rem(420px);
			margin: 0 auto; }

		img {
			display: block;
			width: 100%;
			height: auto; } }


	&__copy {
		@include child-fix();

		@media (min-width: 992px) {
			order: 1; } } }
