.text {

	&__section {
		&::before,
		&::after {
			content: '';
			display: block;
			clear: both; } }

	&__image {
		img {
			display: block;
			width: auto;
			height: auto;
			max-width: 100%; }

		&--left {
			padding: 0 rem(10px) 0 0;
			float: left; }

		&--right {
			padding: 0 0 0 rem(10px);
			float: right; } } }
