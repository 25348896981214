.header {
	position: relative;
	color: $color-grey;
	background-color: $color-grey-light;
	filter: drop-shadow(0 rem(2px) rem(6px) #0000005C);
	z-index: 100;

	&--initialized {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		transition: top 1s; }

	&--minimized {
		@media (min-width: 1200px) {
			top: rem(-80px); } }


	&__wrapper {
		@include content(); }

	&__background {
		position: relative;
		background-color: $color-blue-dark;
		color: $color-white;
		filter: drop-shadow(0 rem(2px) rem(6px) #0000005C);
		z-index: 2;

		@media (min-width: 1200px) {

			@supports(clip-path: url('#svg-mask-header')) {
				background-color: transparent;

				&::before {
					content: '';
					position: absolute;
					display: block;
					top: 0;
					right: 0;
					bottom: rem(-120px);
					left: 0;
					background-color: $color-blue-dark;
					clip-path: url('#svg-mask-header'); } } } }

	&__top {
		display: grid;
		grid-template-columns: auto auto;
		justify-content: space-between;
		height: rem(52px);

		@media (min-width: 576px) {
			height: rem(78px); }

		@media (min-width: 1200px) {
			height: rem(168px); } }

	&__divider {
		position: absolute;
		display: block;
		font-size: rem(52px);
		margin-left: rem(108px);
		top: 0;
		color: $color-blue-light;
		pointer-events: none;

		@media (min-width: 576px) {
			font-size: rem(78px);
			margin-left: rem(162px); }


		@media (min-width: 1200px) {
			font-size: rem(184px);
			margin-left: rem(300px); } }

	&__logo {
		position: relative;
		width: rem(102px);
		align-self: center;

		@media (min-width: 576px) {
			width: rem(153px); }


		@media (min-width: 1200px) {
			grid-row: 1 / 3;
			width: rem(324px); }

		a {
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: prct(86px, 324px);
			text-indent: -9999em;
			overflow: hidden;
			background: {
				image: url('../assets/header/logo_light.png');
				size: 100% 100%;
				repeat: no-repeat;
				position: center center; } } }

	&--initialized &__logo {
		transition: all 1s; }

	&--minimized &__logo {
		@media (min-width: 1200px) {
			width: rem(208px);
			padding-top: rem(80px); } }

	&__meta {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		@media (min-width: 1200px) {
			padding: {
				top: rem(40px);
				bottom: rem(40px); } } }

	&__theme {
		margin-right: rem(10px);

		@media (min-width: 1200px) {
			margin: {
				right: rem(15px);
				left: rem(-47px); } }

		&-switch {
			display: block;
			border: none;
			background: none;
			padding: 0;
			appearance: none;
			color: inherit;
			transition: color 0.6s;
			cursor: pointer;

			&:hover {
				color: $color-blue-light;
				transition: color 0.2s; } }

		&-content {
			display: block;

			&--day {
				display: none; } }

		&-icon {
			display: block;
			font-size: rem(20px);

			@media (min-width: 576px) {
				font-size: rem(30px); } }

		&-label {
			display: none; } }

	&__language {
		flex: 1 0 auto;
		@include margin-fix();

		&-list {
			display: flex;
			list-style: none;
			margin: 0 rem(-4px);
			padding: 0;
			font-size: rem(10px);
			line-height: (13px / 10px);

			@media(min-width: 576px) {
				font-size: rem(15px);
				line-height: (19px / 15px); } }

		&-item {

			text-transform: uppercase;
			padding: 0 rem(4px);

			&:not(:first-child) {
				position: relative;
				padding-left: rem(7px);

				&::before {
					content: '|';
					position: absolute;
					display: block;
					width: rem(3px);
					top: 0;
					left: 0;
					text-align: center; } } }

		&-link {
			display: block;

			@at-root span#{&} {
				cursor: default; }

			@at-root a#{&} {
				color: inherit;
				text-decoration: none;
				transition: color 0.6s;

				&:hover {
					color: $color-blue-light;
					text-decoration: none;
					transition: color 0.2s; } } } }

	&__quick {
		@include margin-fix();

		display: none;

		@media (min-width: 1200px) {
			display: flex; }

		&-list {
			display: flex;
			list-style: none;
			margin: 0 rem(-30px);
			padding: 0; }

		&-item {
			font-size: rem(15px);
			line-height: (19px / 15px);
			text-transform: uppercase;
			margin: 0 rem(30px);

			&--active,
			&--trail {
				color: $color-blue-light; } }

		&-link {
			display: block;

			@at-root span#{&} {
				cursor: default; }

			@at-root a#{&} {
				color: inherit;
				text-decoration: none;
				transition: color 0.6s;

				&:hover {
					color: $color-blue-light;
					text-decoration: none;
					transition: color 0.2s; } } } }

	&__burger {
		position: relative;
		display: block;
		appearance: none;
		padding: 0;
		border: none;
		color: inherit;
		width: rem(22px);
		height: rem(20px);
		background: none;
		text-indent: -9999em;
		overflow: hidden;
		margin-left: rem(20px);

		@media (min-width: 576px) {
			width: rem(33px);
			height: rem(30px); }

		@media (min-width: 1200px) {
			display: none; }

		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			width: rem(22px);
			box-sizing: border-box;

			@media (min-width: 576px) {
				width: rem(33px); } }

		&::before {
			top: rem(2px);
			height: rem(16px);
			border: {
				top: rem(2px) solid;
				bottom: rem(2px) solid; }

			@media (min-width: 576px) {
				top: rem(3px);
				height: rem(24px); } }

		&::after {
			top: rem(9px);
			height: 0;
			border: {
				top: rem(2px) solid; }

			@media (min-width: 576px) {
				top: rem(14px); } } }


	&__navigation {
		@include margin-fix();
		margin-bottom: rem(-34px);

		display: none;

		@media (min-width: 1200px) {
			display: flex; }


		&-level {
			&--level-2 {
				position: absolute;
				display: none;
				left: 0;
				right: 0;
				background: $color-blue-light;
				color: $color-white; } }

		&-list {
			list-style: none;
			margin: 0;
			padding: 0;

			&--level-1 {
				display: flex;
				flex-direction: row;
				margin: 0 rem(-20px); }

			&--level-2 {
				@include content();
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-gap: rem(10px) rem(30px);
				padding: {
					top: rem(20px);
					bottom: rem(20px); } }

			&--level-3 {
				color: $color-white; } }


		&-item {
			display: block;

			&--level-1 {
				margin: 0 rem(20px); } }

		&-link {
			text-transform: uppercase;

			@at-root span#{&} {
				cursor: default; }

			@at-root a#{&} {
				color: inherit;
				text-decoration: none;
				transition: color 0.6s;

				&:hover {
					text-decoration: none;
					transition: color 0.2s; } }

			&--level-1 {
				position: relative;
				display: block;
				font-size: rem(17px);
				line-height: (27px / 17px);
				font-weight: 700;

				@at-root a#{&} {
					&:hover {
						color: $color-blue-light; } }

				&::after {
					content: '';
					position: relative;
					display: block;
					width: 0;
					height: 0;
					margin: 0 auto;
					border: {
						// top: rem(17px) solid transparent
						top: none;
						right: rem(17px) solid transparent;
						bottom: rem(34px) solid transparent;
						left: rem(17px) solid transparent; } } }

			&--level-1#{&}--active,
			&--level-1#{&}--trail {
				color: $color-blue-light; }

			&--level-2 {
				font-size: rem(15px);
				line-height: (25px / 15px);
				font-weight: 700;

				@at-root a#{&} {
					&:hover {
						color: $color-blue-dark; } } }

			&--level-2#{&}--active,
			&--level-2#{&}--trail {
				color: $color-blue-dark; }

			&--level-3 {
				font-size: rem(14px);
				line-height: (20px / 14px);
				font-weight: 400;

				@at-root a#{&} {
					&:hover {
						color: $color-blue-dark; } } }

			&--level-3#{&}--active,
			&--level-3#{&}--trail {
				color: $color-blue-dark; } }


		&-item--level-1:hover &-link--level-1::after {
			border-bottom-color: $color-blue-light; }

		&-item--level-1:hover &-level--level-2 {
			display: block; } }



	&__breadcrumb {
		color: $color-blue-dark;

		@media (min-width: 576px) {
			padding: {
				top: rem(10px);
				bottom: rem(10px); } }

		@media (min-width: 1200px) {
			padding: {
				top: rem(20px);
				bottom: rem(20px); } }

		&-list {
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: rem(9px);
			line-height: (18px / 9px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			@media (min-width: 576px) {
				font-size: rem(13px);
				line-height: (18px / 13px); } }

		&-item {
			display: inline-block;

			&:not(:first-child) {
				&::before {
					content: '»'; } } }

		&-link {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: inherit;
				text-decoration: underline; } } }




	.night-theme & {
		&__background {
			background-color: $color-white;
			color: $color-grey;

			@media (min-width: 1200px) {

				@supports(clip-path: url('#svg-mask-header')) {
					background-color: transparent;

					&::before {
						background-color: $color-white; } } } }

		&__logo {
			a {
				background-image: url('../assets/header/logo_dark.png'); } }

		&__theme {
			&-content {
				&--day {
					display: block; }

				&--night {
					display: none; } } }

		&__navigation {
			color: $color-blue-dark; } } }
