.circle-navigation {
	@include margin-fix();
	max-width: rem(284px);
	margin: 0 auto;

	@media (min-width: 576px) {
		max-width: rem(360px); }

	&--odd {
		padding: rem(7px) 0;

		@media(min-width: 576px) {
			padding: rem(15px) 0; } }

	&__list {
		list-style: none;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: rem(-7px);
		padding: 0;

		@media(min-width: 576px) {
			margin: rem(-15px); } }

	&__item {
		padding: rem(7px);
		width: 50%;
		box-sizing: border-box;

		@media(min-width: 576px) {
			padding: rem(15px); } }

	&--odd &__item:first-child {
		margin: 0 25% rem(-14px);

		@media (min-width: 576px) {
			margin-bottom: rem(-30px); } }

	&__link {
		position: relative;
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		border-radius: 50%;
		overflow: hidden; }

	&__image {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; }

	&__label {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font: {
			size: rem(12px);
			weight: 700; }
		line-height: (15px/12px);
		text-transform: uppercase;
		color: $color-white;
		background-color: rgba($color-blue-dark, 0.6);
		text-align: center;
		padding: rem(15px);
		box-sizing: border-box;

		@media (min-width: 576px) {
			font-size: rem(15px);
			line-height: (19px/15px); } } }
