.module {
	position: relative;
	margin: {
		top: rem(30px);
		bottom: rem(30px); }

	@media (min-width: 576px) {
		margin: {
			top: rem(60px);
			bottom: rem(60px); } }

	&--space {
		&-double {
			margin: {
				top: rem(50px);
				bottom: rem(50px); }

			@media (min-width: 576px) {
				margin: {
					top: rem(100px);
					bottom: rem(100px); } } }

		&-top {
			&-double {
				margin: {
					top: rem(50px); }

				@media (min-width: 576px) {
					margin: {
						top: rem(100px); } } } }

		&-bottom {
			&-double {
				margin: {
					bottom: rem(50px); }

				@media (min-width: 576px) {
					margin: {
						bottom: rem(100px); } } } } }

	&__anchor {
		position: absolute;
		top: rem(-208px) /* 228 (header) - 80 (fixed) + 60 (space) */;
		width: 1px;
		height: 1px;
		overflow: hidden; }

	&__wrapper {
		@include content();
		@include margin-fix(); } }
