.teaser {

	&__list {
		display: grid;
		grid-gap: rem(30px) rem(15px);
		grid-template-columns: repeat(2, minmax(0, 300px));
		justify-content: space-between;

		@media (min-width: 576px) {
			grid-gap: rem(60px) rem(30px); }

		@media (min-width: 992px) {
			grid-template-columns: repeat(3, minmax(0, 300px)); } }

	&--latest &__list--image,
	&--latest &__list--content {
		display: block;
		margin: {
			left: rem(-30px);
			right: rem(-30px); } }

	&--list &__list {
		grid-row-gap: rem(15px);
		grid-template-columns: 100%;

		@media (min-width: 576px) {
			grid-row-gap: rem(30px); } }

	&--circle &__list {
		grid-template-columns: repeat(2, minmax(0, 240px));

		@media (min-width: 992px) {
			grid-template-columns: repeat(3, minmax(0, 240px)); } }

	&__item {
		&:last-child:not(:nth-child(even)) {
			display: none;

			@media (min-width: 992px) {
				display: initial; } } }

	&--latest &__list--image &__item,
	&--latest &__list--content &__item {
		&:last-child:not(:nth-child(even)) {
				display: initial; } }

	&--list &__item {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start; }

	&__image {
		img {
			display: block;
			width: 100%;
			height: auto; } }

	&--latest &__list--content &__image {
		display: none; }

	&--circle &__image {
		border-radius: 50%;
		overflow: hidden; }

	&--list &__image {
		flex: 0 0 auto;
		margin-right: rem(15px);

		@media (min-width: 576px) {
			margin-right: rem(30px); } }

	&--list &__content {
		flex: 1 1 auto; }

	&--latest &__list--image &__content {
		display: none; }

	&--latest &__list--content &__content {
		padding: {
			left: rem(30px);
			right: rem(30px); } }

	&__head {
		margin: {
			top: rem(5px);
			bottom: rem(5px); }

		@media (min-width: 576px) {
			margin: {
				top: rem(15px);
				bottom: rem(15px); } } }

	&--latest &__list--content &__head {
		max-width: rem(220px);
		margin: 0 auto;
		text-align: center; }

	&--list &__head {
		margin: 0; }

	&__body {
		margin: {
			top: rem(5px);
			bottom: rem(5px); }

		@media (min-width: 576px) {
			margin: {
				top: rem(15px);
				bottom: rem(15px); } } }

	&--list &__body {
		margin: 0; }

	&__title {
		margin: 0; }

	&--latest &__list--content &__title {
		font-size: rem(16px);
		line-height: (17px / 16px); }

	&__date {
		font-size: rem(10px);
		line-height: (15px / 10px);

		@media (min-width: 576px) {
			font-size: rem(14px);
			line-height: (21px / 14px); } }

	&--latest &__list--content &__date {
		font-size: rem(12px);
		line-height: (15px / 12px); }

	&__meta {
		font: {
			size: rem(10px);
			style: italic; }
		line-height: (15px / 10px);
		color: $color-blue-dark;

		@media (min-width: 576px) {
			font-size: rem(14px);
			line-height: (21px / 14px); } }

	&--latest &__list--content &__meta {
		font-size: rem(12px);
		line-height: (15px / 12px); }


	&__copy {
		> *:first-child {
			margin-top: 0; }

		> *:last-child {
			margin-bottom: 0; } }

	&--latest &__list--content &__copy {
		font-size: rem(14px);
		line-height: (15px / 14px); }

	&--latest &__list--content &__foot {
		max-width: rem(220px);
		margin: 0 auto;
		text-align: center; }

	&__menu {
		margin: {
			top: rem(15px);
			bottom: rem(15px); } }

	&__pagination {
		margin-top: rem(60px);
		text-align: center;

		&-more {
			display: inline-block;
			width: 0;
			height: 0;
			overflow: hidden;
			color: $color-blue-dark;
			border: {
				top: rem(21px) solid;
				left: rem(12px) solid transparent;
				right: rem(12px) solid transparent; } } }


	.night-theme & {

		&__meta {
			color: $color-white; }

		&__pagination {
			&-more {
				color: $color-white; } } } }
