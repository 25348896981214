@function rem($size) {
	@if $size == 0 {
		@return 0; }
	@return $size / $base-font-size * 1rem; }

@function em($size, $base-size: $base-font-size) {
	@if $size == 0 {
		@return 0; }
	@return $size / $base-size * 1em; }

@function prct($size, $base-size) {
	@if $size == 0 {
		@return 0; }
	@return $size / $base-size * 100%; }
