.filter {
	display: grid;
	grid-gap: rem(15px);
	grid-template-columns: 100%;
	justify-content: space-between;

	@media (min-width: 576px) {
		grid-gap: rem(15px) rem(30px); }

	@media (min-width: 992px) {
		grid-template-columns: repeat(3, minmax(0, 300px)); }

	&__widget {
		text-align: right;

		@media (min-width: 992px) {
			text-align: center; }

		&--search {
			order: 1;

			@media (min-width: 992px) {
				order: unset; } }

		&:not(#{&}--search) {
			order: 2;

			@media (min-width: 992px) {
				order: unset; } } } }
