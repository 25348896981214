.hero-slider {
	position: relative;

	&__intro {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		margin: rem(-30px) rem(-30px) 0;
		padding: rem(75px) rem(40px);
		height: calc(100vh - #{(rem(70px))});
		color: $color-white;
		background: {
			color: $color-blue-dark;
			image: url(../assets/hero-slider/background.jpg);
			size: cover;
			position: center bottom; }
		box-sizing: border-box;
		text-align: center;

		@media(min-width: 576px) {
			display: block;
			background: none;
			margin: 0;
			height: auto;
			text-align: left;
			padding: rem(10px) 0 0;
			color: $color-blue-light;
			z-index: 2; }

		@media(min-width: 992px) {
			position: absolute;
			top: 0;
			left: 0; }

		&-headline {
			font: {
				size: rem(16px);
				weight: 700; }
			line-height: (20px / 16px);
			text-transform: uppercase; }

		&-copy {
			max-width: rem(285px);
			font-size: rem(14px);
			line-height: (15px / 14px); } }

	&__planets {
		position: relative;
		z-index: 1;

		&-item {}

		&-decorator {
			position: relative;
			width: prct(285px, 315px);
			height: 0;
			padding-bottom: prct(285px, 315px);
			margin: prct(15px, 315px);

			@media (min-width: 576px) {
				width: prct(460px, 1200px);
				padding-bottom: prct(460px, 1200px);
				margin: prct(110px, 1200px) prct(375px, 1200px) prct(30px, 1200px) prct(365px, 1200px); } }

		&-circle {
			position: absolute;
			display: block;
			opacity: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: rotate(-30deg);
			transition: opacity 0.5s, transform 1.5s;
			color: $color-blue-light;

			.slick-slide.slick-current & {
				transform: rotate(0deg);
				opacity: 1;
				transition: opacity 0.5s 1s, transform 1s 1s; } }

		&-image {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			padding: prct(44px, 460px);
			box-sizing: border-box;

			img {
				display: block;
				width: 100%;
				height: auto;
				border-radius: 50%; } } }

	&__menu {
		position: relative;
		max-width: rem(375px);
		margin: {
			left: auto;
			right: auto; }
		z-index: 2;

		@media (min-width: 992px) {
			max-width: prct(375px,1200px);
			margin: {
				top: prct(-170px, 1200px);
				right: 0; }

			.slick-dots {
				justify-content: flex-start; } } }

	&__contents {
		position: relative;
		max-width: rem(375px);
		margin: {
			left: auto;
			right: auto; }
		z-index: 2;

		@media (min-width: 992px) {
			max-width: prct(375px,1200px);
			margin: {
				right: 0; } } } }
