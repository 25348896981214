.quick-links {
	position: fixed;
	display: none;

	@media(min-width: 768px) {
		display: block; }

	&--left {
		top: rem(176px);
		left: 0;
		width: rem(75px);

		@media (min-width: 1200px) {
			top: rem(286px); }

		@media (min-width: rem(1350px)) {
			width: calc(50% - 600px); }

		@media (min-width: rem(1440px)) {
			left: calc(50% - 720px);
			width: rem(120px); } }

	&--right {
		top: rem(285px);
		bottom: rem(160px);
		right: 0;
		width: rem(75px);

		@media (min-width: rem(1350px)) {
			width: calc(50% - 600px); }

		@media (min-width: rem(1440px)) {
			right: calc(50% - 720px);
			width: rem(120px); } }

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center; }

	&--left &__list {
		justify-content: flex-start; }

	&--right &__list {
		justify-content: flex-end; }

	&__item {
		margin: {
			top: rem(35px);
			bottom: rem(35px); }

		&:first-child {
			margin-top: 0; }

		&:last-child {
			margin-bottom: 0; } }

	&__link {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(45px);
		height: rem(45px);
		border-radius: 50%;
		background-color: $color-blue-dark;
		color: $color-white;
		transition: background-color 0.6s;

		&:hover {
			background-color: $color-blue-light;
			text-decoration: none;
			transition: background-color 0.2s; }


		&[href*="#"] {
			&::after {
				content: '';
				position: absolute;
				display: block;
				width: 0;
				height: 0;
				border: {
					top: rem(6px) solid $color-blue-dark;
					left: rem(3px) solid transparent;
					right: rem(3px) solid transparent; }

				left: 50%;
				top: prct(50px, 45px);
				margin: rem(-3px);
				transition: border-color 0.6s; }

			&:hover::after {
				border-top-color: $color-blue-light;
				transition: border-color 0.2s; } } }


	&__icon {
		// font-size: rem(27px)
		width: rem(25px);
		height: rem(25px); }

	&__label {
		display: none; }

	&--left &__link {
		background-color: $color-blue-light;

		&:hover {
			background-color: $color-blue-dark; }

		&[href*="#"] {
			&::after {
				border-top-color: $color-blue-light; }

			&:hover::after {
				border-top-color: $color-blue-dark; } } }

	&--right &__link {
		background-color: $color-blue-dark;

		&:hover {
			background-color: $color-blue-light; }

		&[href*="#"] {
			&::after {
				border-top-color: $color-blue-dark; }

			&:hover::after {
				border-top-color: $color-blue-light; } } }


	.night-theme & {

		&__link {
			background-color: $color-white;
			color: $color-blue-dark;

			&:hover {
				background-color: $color-blue-light; }

			&[href*="#"] {
				&::after {
					border-top-color: $color-white; }

				&:hover::after {
					border-top-color: $color-blue-light; } } }


		&--left &__link {
			&:hover {
				background-color: $color-white; }

			&[href*="#"] {
				&:hover::after {
					border-top-color: $color-white; } } }

		&--right &__link {
			background-color: $color-white;

			&[href*="#"] {
				&::after {
					border-top-color: $color-white; } } } } }
