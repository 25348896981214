.news {
	display: grid;
	grid-template-columns: minmax(0, #{(rem(480px))});
	justify-content: space-around;
	grid-gap: rem(50px) rem(15px);

	@media (min-width: 576px) {
		grid-gap: rem(100px) rem(30px); }

	@media (min-width: 992px) {
		grid-template-columns: minmax(0, #{(rem(600px))}) minmax(0, #{(rem(480px))}); }

	&__head {
		margin-bottom: rem(60px); }

	&__body {
		.scrollpane {
			max-height: rem(375px);
			overflow: hidden; } }

	&__teaser {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		min-height: rem(375px);
		padding: rem(15px) 0;
		box-sizing: border-box;

		&-head {
			margin-bottom: rem(15px); }

		&-body {
			margin: {
				bottom: rem(15px); } }


		&-title {
			margin: 0; } } }
