.employees {
	@media (min-width: 992px) {
		margin-right: rem(250px); }

	&__image {
		margin-bottom: rem(30px);

		img {
			display: block;
			width: 100%;
			height: auto; } }

	&__head {
		@include child-fix();
		margin-bottom: rem(15px);

		@media (min-width: 576px) {
			margin-bottom: rem(30px); } }

	&__list {
		display: grid;
		grid-gap: rem(15px);
		grid-template-columns: 1fr;
		justify-content: stretch;

		@media (min-width: 576px) {
			grid-gap: rem(30px);
			grid-template-columns: 1fr 1fr; } }

	&__item {
		max-width: rem(320px);

		@media (min-width: 576px) {
			max-width: rem(400px); }

		&-name {
			font-weight: 700;
			color: $color-blue-dark; }

		&-position {
			color: $color-blue-dark;
			font-style: italic; }

		&-assignments {} }


	.night-theme & {
		&__item {
			&-name {
				color: $color-white; }

			&-position {
				color: $color-white;
				font-style: italic; } } } }
