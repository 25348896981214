.footer {
	position: relative;
	color: $color-grey;
	background-color: $color-grey-light;
	filter: drop-shadow(0 rem(-2px) rem(6px) #0000005C);
	z-index: 10;

	&__wrapper {
		@include content(); }

	&__background {
		position: relative;
		color: $color-white;
		background-color: $color-blue-dark;
		filter: drop-shadow(0 rem(-2px) rem(6px) #0000005C);
		z-index: 2;

		@supports(clip-path: url('#svg-mask-header')) {
			background-color: transparent;

			&::before {
				content: '';
				position: absolute;
				display: block;
				top: rem(-34px);
				right: 0;
				bottom: 0;
				left: 0;
				background-color: $color-blue-dark;
				clip-path: url('#svg-mask-footer');

				@media (min-width: 576px) {
					top: rem(-44px); }

				@media (min-width: 1200px) {
					top: rem(-90px); } } } }

	&__top {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: {
			top: rem(10px);
			bottom: rem(10px); }

		@media (min-width: 576px) {
			padding: {
				top: rem(15px);
				bottom: rem(15px); } }

		@media (min-width: 1200px) {
			justify-content: space-between;
			padding: {
				top: rem(40px);
				bottom: rem(40px); } } }

	&__bottom {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: {
			top: rem(10px);
			bottom: rem(10px); }

		@media (min-width: 576px) {
			padding: {
				top: rem(15px);
				bottom: rem(15px); } }

		@media (min-width: 1200px) {
			padding: {
				top: rem(40px);
				bottom: rem(40px); } } }

	&__contact {
		max-width: rem(130px);

		@media (min-width: 1200px) {
			max-width: rem(150px); }

		@media (min-width: 1200px) {
			max-width: none; }

		&-head {
			font: {
				size: rem(11px);
				weight: 700; }
			line-height: (13px / 11px);
			color: $color-blue-dark;

			@media (min-width: 576px) {
				font-size: rem(13px);
				line-height: (15px / 13px); } }

		&-body {
			font-size: rem(11px);
			line-height: (13px / 11px);

			@media (min-width: 576px) {
				font-size: rem(11px);
				line-height: (15px / 11px);
				margin: rem(15px) 0; } }

		&-foot {
			font-size: rem(11px);
			line-height: (13px / 11px);

			@media (min-width: 576px) {
				font-size: rem(13px);
				line-height: (15px / 13px); } } }

	&__sitemap {
		display: none;
		max-width: rem(764px);
		margin: 0 0 0 auto;

		@media (min-width: 1200px) {
			display: block; }

		&-level {}

		&-list {
			list-style: none;
			margin: 0;
			padding: 0 0 0 rem(12px);
			font-size: rem(11px);
			line-height: (15px / 11px);

			&--level-1 {
				display: grid;
				grid-template-columns: repeat(4, minmax(0,1fr));
				grid-gap: rem(30px);
				justify-content: space-between;
				padding-left: 0; }

			&--level-2 {
				padding-left: 0; } }

		&-item {}

		&-link {
			color: inherit;

			&--level-1 {
				text-transform: uppercase;
				font-weight: 700; } } }

	&__logo {
		position: relative;
		padding: 0 rem(10px);

		@media (min-width: 576px) {
			padding: 0 rem(25px); }

		&:first-child {
			padding-left: 0; }

		&:last-child {
			padding-right: 0; }

		a {
			display: block;
			width: 100%;
			height: 0;
			text-indent: -9999em;
			overflow: hidden;
			background: {
				size: 100% 100%;
				repeat: no-repeat;
				position: center center; } }

		&--self {
			width: rem(102px);

			@media (min-width: 576px) {
				width: rem(153px); }

			@media (min-width: 1200px) {
				width: rem(324px); }

			a {
				padding-bottom: prct(86px, 324px);
				background-image: url('../assets/header/logo_light.png'); } }

		&--association {
			width: rem(34px);

			@media (min-width: 576px) {
				width: rem(51px); }

			@media (min-width: 1200px) {
				width: rem(107px); }

			a {
				padding-bottom: prct(86px, 107px);
				background-image: url('../assets/footer/logo_association_light.png'); } }

		&--equality {
			width: rem(25px);

			@media (min-width: 576px) {
				width: rem(37px); }

			@media (min-width: 1200px) {
				width: rem(78px); }

			a {
				padding-bottom: prct(92px, 78px);
				background-image: url('../assets/footer/logo_equality_light.png'); } } }

	.night-theme & {
		&__background {
			background-color: $color-white;
			color: $color-grey;

			@supports(clip-path: url('#svg-mask-footer')) {
				background-color: transparent;

				&::before {
					background-color: $color-white; } } }

		&__logo {
			&--self {
				a {
					background-image: url('../assets/header/logo_dark.png'); } }

			&--association {
				a {
					background-image: url('../assets/footer/logo_association_dark.png'); } }

			&--equality {
				a {
					background-image: url('../assets/footer/logo_equality_dark.png'); } } } } }
