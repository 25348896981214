@import "jscrollpane/style/jquery.jscrollpane";

.jspVerticalBar {
	width: rem(14px);
	background: transparent; }

.jspArrow {
	color: $color-blue-dark;
	background: transparent;
	height: 0;
	width: 0;

	.jspVerticalBar & {
		height: 0;
		border-left: rem(7px) solid transparent;
		border-right: rem(7px) solid transparent; }

	.jspVerticalBar &Up {
		height: 0;
		border-bottom: rem(16px) solid; }

	.jspVerticalBar &Down {
		height: 0;
		border-top: rem(16px) solid; } }

.jspHorizontalBar {
	display: none; }

.jspDisabled {
	color: rgba($color-blue-dark, 0.3);
	background: transparent; }

.jspTrack {
	background: transparent; }

.jspDrag {
	display: none; }


.night-theme {
	.jspArrow {
		color: $color-white; }

	.jspDisabled {
		color: rgba($color-white, 0.3); } }


.scrollpane {
	max-height: rem(500px);
	overflow-x: hidden;
	overflow-y: auto;

	&__content {
		padding-right: rem(15px);

		@media (min-width: 576px) {
			padding-right: rem(30px); } } }
