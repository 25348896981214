.calendar {
	display: grid;
	grid-template-columns: minmax(0, #{(rem(480px))});
	justify-content: space-around;
	grid-gap: rem(30px) rem(15px);

	@media (min-width: 576px) {
		grid-gap: rem(60px) rem(30px); }

	@media (min-width: 992px) {
		grid-template-columns: rem(360px) minmax(0, #{(rem(680px))});
		justify-content: space-between; }

	&__widget {

		&-navigation {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			max-width: rem(250px);
			margin: 0 auto rem(30px);
			color: $color-blue-dark; }

		&-prev,
		&-next {
			color: inherit;
			display: block;
			width: 0;
			height: 0;
			border: {
				top: rem(5px) solid transparent;
				bottom: rem(5px) solid transparent; } }

		&-prev {
			border-right: rem(11px) solid; }

		&-next {
			border-left: rem(11px) solid; }

		&-month {
			font: {
				size: rem(18px);
				weight: 700; }
			line-height: (20px/18px); }

		&-filter {
			margin: rem(30px) 0;
			text-align: center; }

		&-grid {
			display: grid;
			grid-gap: rem(16px);
			grid-template-columns: repeat(7, #{(rem(30px))});
			justify-content: center;
			overflow-x: auto;

			@media (min-width: 576px) {
				grid-gap: rem(18px);
				grid-template-columns: repeat(7, #{(rem(34px))}); } }

		&-weekday {
			font-size: rem(13px);
			line-height: (16px / 13px);
			text-align: center;
			text-transform: uppercase;

			@media (min-width: 576px) {
				font-size: rem(15px);
				line-height: (19px / 15px); } }


		&-day {
			position: relative;
			display: block;
			width: rem(30px);
			height: rem(30px);
			border: 1px solid;
			border-radius: 50%;
			box-sizing: border-box;
			font-size: rem(12px);
			line-height: (30px / 12px);
			text-align: center;
			color: inherit;
			text-decoration: none;

			@media (min-width: 576px) {
				width: rem(34px);
				height: rem(34px);
				font-size: rem(13px);
				line-height: (34px / 13px); }

			&:hover {
				text-decoration: none; }

			&--current {
				&::before {
					content: '';
					position: absolute;
					display: block;
					left: 50%;
					bottom: rem(-7px);
					width: rem(6px);
					height: rem(6px);
					margin: rem(-3px);
					background-color: $color-grey;
					border-radius: 50%; } }

			&--yellow {
				background-color: $color-yellow;
				border-color: $color-yellow;
				color: $color-grey; }

			&--blue-light {
				background-color: $color-blue-light;
				border-color: $color-blue-light;
				color: $color-white; }

			&--blue-dark {
				background-color: $color-blue-dark;
				border-color: $color-blue-dark;
				color: $color-white; }

			&--blue-dark#{&}--blue-light {
				box-shadow: 5px 0 0 $color-blue-light; }

			&--blue-dark#{&}--yellow,
			&--blue-light#{&}--yellow {
				box-shadow: -5px 0 0 $color-yellow; }

			&--blue-dark#{&}--blue-light#{&}--yellow {
				box-shadow: -5px 0 0 $color-yellow, 5px 0 0 $color-blue-light; } } }


	&__content {
		display: flex;
		flex-direction: column;
		align-items: stretch; }

	&__preview {
		order: 2;

		@media (min-width: 992px) {
			order: 1; }

		.scrollpane {
			max-height: rem(300px); } }

	&__teaser {
		position: relative;
		padding-left: rem(20px);
		margin: {
			top: rem(15px);
			bottom: rem(15px); }

		@media (min-width: 576px) {
			padding-left: rem(30px);
			margin: {
				top: rem(30px);
				bottom: rem(30px); } }

		&:first-child {
			margin-top: 0; }

		&:last-child {
			margin-bottom: 0; }

		&::before {
			content: '';
			position: absolute;
			display: block;
			top: rem(2px);
			left: 0;
			width: rem(12px);
			height: rem(12px);
			border-radius: 50%;

			@media (min-width: 576px) {
				top: rem(1px);
				width: rem(18px);
				height: rem(18px); } }

		&--blue-dark {
			&::before {
				background-color: $color-blue-dark; } }

		&--blue-light {
			&::before {
				background-color: $color-blue-light; } }

		&--yellow {
			&::before {
				background-color: $color-yellow; } }

		&-title {
			margin: 0; }

		&-date {
			font-size: rem(10px);
			line-height: (15px / 10px);

			@media (min-width: 576px) {
				font-size: rem(14px);
				line-height: (21px / 14px); } }

		&-meta {
			font: {
				size: rem(10px);
				style: italic; }
			line-height: (15px / 10px);
			color: $color-blue-dark;

			@media (min-width: 576px) {
				font-size: rem(14px);
				line-height: (21px / 14px); } }

		&-copy {
			> *:first-child {
				margin-top: 0; }

			> *:last-child {
				margin-bottom: 0; } }

		&-link {} }


	&__legend {
		@include child-fix();
		order: 1;
		align-self: flex-end;
		margin-bottom: rem(30px);

		@media (min-width: 576px) {
			margin-bottom: rem(60px); }

		@media (min-width: 992px) {
			order: 2;
			align-self: flex-start;
			margin: {
				bottom: 0;
				top: rem(60px); } }

		&-entry {
			position: relative;
			margin: rem(10px) 0;
			padding-left: rem(18px);
			font-size: rem(10px);
			line-height: (13px / 10px);
			color: $color-blue-dark;

			&::before {
				content: '';
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: rem(13px);
				height: rem(13px);
				border-radius: 50%; }

			@media (min-width: 576px) {
				font-size: rem(11px);
				line-height: (15px / 11px);
				padding-left: rem(25px);

				&::before {
					width: rem(15px);
					height: rem(15px); } }

			&--blue-dark {
				&::before {
					background-color: $color-blue-dark; } }

			&--blue-light {
				&::before {
					background-color: $color-blue-light; } }

			&--yellow {
				&::before {
					background-color: $color-yellow; } } } }


	.night-theme & {
		&__widget {
			&-navigation {
				color: $color-white; }


			&-day {
				&--current {
					&::before {
						background-color: $color-white; } }

				&--yellow {
					color: $color-blue-dark; }

				&--blue-light {
					color: $color-blue-dark; }

				&--blue-dark {
					background-color: $color-white;
					border-color: $color-white;
					color: $color-blue-dark; } } }


		&__teaser {
			&--blue-dark {
				&::before {
					background-color: $color-white; } }

			&-meta {
				color: $color-white; } }


		&__legend {
			&-entry {
				color: $color-white;

				&--blue-dark {
					&::before {
						background-color: $color-white; } } } } } }
