label {
	display: inline-block;
	font-size: rem(12px);
	line-height: (15px / 12px);
	color: $color-blue-dark;
	margin-right: rem(5px);

	@media (min-width: 576px) {
		font-size: rem(14px);
		line-height: (18px / 14px);
		margin-right: rem(10px); } }

select {
	display: inline-block;
	border: 1px solid;
	height: rem(28px);
	border-radius: rem(3px);
	padding: rem(7px) rem(10px);
	background: transparent;
	box-sizing: border-box;
	font-size: rem(10px);
	line-height: (12px / 10px);
	color: $color-blue-dark;
	background-color: $color-white;
	width: rem(160px);
	max-width: 100%; }


input[type="text"],
input[type="password"] {
	display: inline-block;
	border: 1px solid;
	height: rem(28px);
	border-radius: rem(3px);
	padding: rem(7px) rem(10px);
	background: transparent;
	box-sizing: border-box;
	font-size: rem(10px);
	line-height: (12px / 10px);
	color: $color-blue-dark;
	background-color: $color-white;
	width: rem(200px);
	max-width: 100%;

	&::placeholder {
		color: $color-grey; } }

input[type="checkbox"] {
	position: absolute;
	display: block;
	width: 1px;
	height: 1px;
	opacity: 0.01;

	+ label {
		display: inline-block;
		margin-right: none;

		.checkbox-switch {
			vertical-align: middle;
			display: inline-block;
			border: 1px solid;
			border-radius: rem(3px);
			font-size: rem(10px);
			line-height: (12px / 10px);
			margin-right: rem(10px);

			&__off {
				position: relative;
				display: inline-block;
				height: rem(18px);
				padding: {
					top: rem(3px);
					right: rem(6px);
					bottom: rem(3px);
					left: rem(4px); }
				background-color: $color-blue-dark;
				color: $color-white;
				box-sizing: border-box;

				&::after {
					content: '';
					position: absolute;
					display: inline-block;
					top: 0;
					right: 0;
					border-top: rem(18px) solid transparent;
					border-right: rem(3px) solid $color-white; } }

			&__on {
				position: relative;
				display: inline-block;
				height: rem(18px);
				padding: {
					top: rem(3px);
					right: rem(4px);
					bottom: rem(3px);
					left: rem(3px); }
				box-sizing: border-box; } } }

	&:checked + label {
		.checkbox-switch {
			background-color: $color-blue-dark;
			border-color: $color-blue-dark;
			color: $color-white;

			&__off {
				background-color: $color-white;
				color: $color-blue-dark;

				&::after {
					border-right-color: $color-blue-dark; } } } } }


.night-theme {

	label {
		color: $color-white; }

	select {
		color: $color-white;
		background-color: $color-blue-dark; }

	input[type="text"],
	input[type="password"] {
		color: $color-white;
		background-color: $color-blue-dark;

		&::placeholder {
			color: $color-white; } }

	input[type="checkbox"] {
		+ label {
			.checkbox-switch {
				border: 1px solid $color-white;
				background-color: $color-blue-dark;
				color: $color-white;

				&__off {
					background-color: $color-white;
					color: $color-blue-dark;

					&::after {
						border-right-color: $color-blue-dark; } } } }

		&:checked + label {
			.checkbox-switch {
				background-color: $color-white;
				color: $color-blue-dark;

				&__off {
					background-color: $color-blue-dark;
					color: $color-white;

					&::after {
						border-right-color: $color-white; } } } } } }
